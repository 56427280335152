var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.value
      ? 'v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-input--selection-controls v-input--checkbox primary--text'
      : 'v-input v-input--dense theme--light v-input--selection-controls v-input--checkbox'},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-input--selection-controls__input"},[_c('i',{class:_vm.value
              ? 'v-icon notranslate v-icon--dense mdi mdi-checkbox-marked theme--light primary--text'
              : 'v-icon notranslate v-icon--dense mdi mdi-checkbox-blank-outline theme--light',attrs:{"aria-hidden":"true"},on:{"click":_vm.changeCheckbox}}),_c('input',{staticClass:"fix--css--properties",attrs:{"aria-checked":_vm.value ? 'true' : 'false',"role":"checkbox","type":"checkbox","readonly":_vm.readonly,"disabled":_vm.disabled},domProps:{"checked":_vm.value}}),_c('div',{class:_vm.value
              ? 'v-input--selection-controls__ripple primary--text'
              : 'v-input--selection-controls__ripple',on:{"click":_vm.changeCheckbox}})]),_c('label',{staticClass:"v-label theme--light",staticStyle:{"left":"0px","right":"auto","position":"relative","cursor":"default"}},[_vm._v(_vm._s(_vm.label))])]),_c('div',{class:_vm.value
          ? 'v-messages theme--light primary--text'
          : 'v-messages theme--light'},[_c('div',{staticClass:"v-messages__wrapper"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }