import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Version: process.env.VUE_APP_VERSION,
  },
})

export default {
  login(payload) {
    return apiClient.post(`/api/v1/login`, payload)
  },
  registerUser(payload) {
    return apiClient.post(`/api/v1/users`, payload)
  },
  getUsers() {
    return apiClient.get(`/api/v1/users?pageSize=1000`)
  },
  getProfile() {
    return apiClient.get(`/api/v1/profile`)
  },
  updateProfile(payload) {
    return apiClient.patch(`/api/v1/profile`, payload)
  },
  updateUser(payload) {
    return apiClient.patch(`/api/v1/users/${payload.id}`, payload)
  },
  changePassword(payload) {
    return apiClient.post(`/api/v1/password/set`, payload)
  },
  resetPassword(payload) {
    return apiClient.put(`/api/v1/password/reset`, payload)
  },
  impersonateUser(userId) {
    return apiClient.put(`/api/v1/users/impersonate/${userId}`)
  },
  cancelImpersonation() {
    return apiClient.put(`/api/v1/users/impersonate/cancel`)
  },
}
