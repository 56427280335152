import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { en } from '@/plugins/en'
import { lt } from '@/plugins/lt'

Vue.use(VueI18n)

const messages = {
  en: en,
  lt: lt,
}

export default new VueI18n({
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  locale: localStorage.getItem('lang') || 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})
