import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Version: process.env.VUE_APP_VERSION,
  },
})

export default {
  getClaims(pipelineId, orderBy, page = 1, pageSize, params) {
    return apiClient.get(
      `/api/v1/claims?pageSize=${pageSize}&pipelineId=${pipelineId}&orderBy=${orderBy}&page=${page}${params}`
    )
  },
  getClaim(claimId) {
    return apiClient.get(`/api/v1/claims/${claimId}`)
  },
  changeStage(payload) {
    return apiClient.patch(`/api/v1/claims/${payload.claimId}`, payload)
  },
  unfollowClaim(id) {
    return apiClient.put(`/api/v1/claims/${id}/unfollow`)
  },
  followClaim(id) {
    return apiClient.put(`/api/v1/claims/${id}/follow`)
  },
  deleteClaim(claimId) {
    return apiClient.delete(`/api/v1/claims/${claimId}`)
  },
  getClaimsByClient(clientId) {
    return apiClient.get(`/api/v1/clients/${clientId}/claims`)
  },
  updateClaim(claimId, payload) {
    return apiClient.patch(`/api/v1/claims/${claimId}`, payload)
  },
  importClaims(data) {
    return apiClient.post('/api/v1/import', { data })
  },
  createClaim(claim) {
    return apiClient.post('/api/v1/claims', claim)
  },
  getClaimNumber(pipelineId) {
    return apiClient.put(`/api/v1/pipelines/${pipelineId}/issue-claim-number`)
  },
  async getSuggestions(clientId, fieldName, searchText) {
    return apiClient.get(
      `/api/v1/suggestions/clients/${clientId}/claims/${fieldName}?search=${searchText}`
    )
  },
}
