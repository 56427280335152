<template>
  <v-navigation-drawer
    class="notificationDrawer"
    v-model="showDrawer"
    temporary
    right
    app
  >
    <v-list dense>
      <v-list-item link :class="!limit ? 'v-list-item--active accent' : ''">
        <v-list-item-content @click="limit = !limit">
          <v-list-item-title>{{
            $t('notificationBar.showAll')
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list class="d-flex flex-row">
        <v-list-item v-if="!edit" dense>
          <v-list-item-content>
            <v-btn x-small @click="toggleEdit">Edit</v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="edit" dense>
          <v-list-item-content>
            <v-btn
              :disabled="selectedNotifications.length == 0"
              @click="deleteSelectedNotifications"
              color="error"
              x-small
              >Delete</v-btn
            >
          </v-list-item-content>
          <v-list-item-content>
            <v-btn @click="toggleEdit" color="" x-small>Cancel</v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-if="edit" class="d-flex flex-row">
        <v-list-item dense>
          <v-list-item-content>
            <v-btn color="secondary" @click="selectAllNotifications" x-small
              >Select all</v-btn
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item
        v-for="notification in computedNotifications"
        :key="notification.title"
        link
      >
        <v-list-item-icon v-if="edit" class="custom-icon-margin">
          <v-checkbox
            class="small-checkbox"
            v-model="selectedNotifications"
            :value="notification.id"
          ></v-checkbox>
        </v-list-item-icon>
        <v-list-item-icon>
          <v-icon :color="getIconColor(notification)">{{
            getIcon(notification)
          }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content @click="markAndRedirect(notification)">
          <v-list-item-title
            :class="notification.seenAt ? 'text--disabled' : ''"
            >{{ getTitle(notification) }}</v-list-item-title
          >
          <v-list-item-subtitle>{{
            timeTo(notification.createdAt)
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon>
              <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
            </v-list-item-icon>
          </template>
          <v-list>
            <!-- <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item> -->
            <v-list-item link v-if="notification.seenAt">
              <v-list-content @click="markUnread(notification)">
                <v-list-item-title>Mark unread</v-list-item-title>
              </v-list-content>
            </v-list-item>
            <v-list-item link v-if="!notification.seenAt">
              <v-list-content @click="markRead(notification)">
                <v-list-item-title>Mark read</v-list-item-title>
              </v-list-content>
            </v-list-item>
            <v-list-item link>
              <v-list-content>
                <v-list-item-title @click="deleteNotification(notification)"
                  >Delete</v-list-item-title
                >
              </v-list-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-list-item-icon v-if="notification.seenAt">
          <v-icon color="orange" @click="markUnread(notification)">
            mdi-eye
          </v-icon>
        </v-list-item-icon> -->
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
/*
TODO: Add mark all as read functionality.
TODO: Move Notification item to a seperate component. */
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
export default {
  props: {
    drawerShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      limit: true,
      //Delete functionality variables
      edit: false,
      selectedNotifications: [],
    }
  },
  methods: {
    timeTo(timeString) {
      return dayjs().to(timeString)
    },
    getIconColor(notification) {
      return notification.seenAt ? 'orange lighten-4' : 'orange'
    },
    getTitle(notification) {
      switch (notification.event) {
        case 'claim-created':
          return this.$i18n.t('notificationBar.newClaimCreated')
        case 'reminder-claim':
          return notification.message
            ? `${notification.message}`
            : `${notification.claimId}`
        case 'claim-number-assigned':
          return `${this.$i18n.t(
            'notificationBar.claimNumberHasBeenAssigned'
          )}: ${notification.claimNumber}.`
        case 'claim-stage-changed':
          return `${this.$i18n.t('notificationBar.claimStageHasChanged')}.`
        case 'claim-first-message':
          return `${this.$i18n.t('notificationBar.messageInClaim')} ${
            notification.claimId
          }.`
        case 'claim-attachments-updated':
          return `${this.$i18n.t('notificationBar.fileChangesInClaim')} ${
            notification.claimId
          }`
        case 'claim-final-stage':
          return `${this.$i18n.t('notificationBar.claim')} ${
            notification.claimNumber
              ? notification.claimNumber
              : notification.claimId
          } - ${
            notification.stageName
              ? this.$i18n.t(`stages["${notification.stageName}"]`)
              : this.$i18n.t(`stages["closed"]`)
          }.`
      }
    },
    getIcon(notification) {
      switch (notification.event) {
        case 'claim-created':
          return 'mdi-file-plus'
        case 'reminder-claim':
          return 'mdi-clock'
        case 'claim-number-assigned':
          return 'mdi-stamper'
        case 'claim-stage-changed':
          return 'mdi-update'
        case 'claim-first-message':
          return 'mdi-message-text-fast-outline'
        case 'claim-attachments-updated':
          return 'mdi-file-document-plus'
        case 'claim-final-stage':
          return 'mdi-flag-checkered'
      }
    },
    // Damn this be ugly AF.
    markAndRedirect(notification) {
      if (notification.pipelineSlug) {
        if (notification.event == 'claim-first-message') {
          if (!notification.seenAt) {
            this.$store
              .dispatch('notification/markRead', notification.id)
              .then(() => {
                if (
                  this.$router.currentRoute.fullPath !=
                  `/${notification.pipelineSlug}/${notification.claimId}/messages`
                ) {
                  this.$router.push(
                    `/${notification.pipelineSlug}/${notification.claimId}/messages`
                  )
                  this.$store.dispatch('mtpl/getClaim', notification.claimId)
                }
              })
          } else {
            if (
              this.$router.currentRoute.fullPath !=
              `/${notification.pipelineSlug}/${notification.claimId}/messages`
            ) {
              this.$router.push(
                `/${notification.pipelineSlug}/${notification.claimId}/messages`
              )
              this.$store.dispatch('mtpl/getClaim', notification.claimId)
            }
          }
          return
        }
        if (!notification.seenAt) {
          this.$store
            .dispatch('notification/markRead', notification.id)
            .then(() => {
              if (
                this.$router.currentRoute.fullPath !=
                `/${notification.pipelineSlug}/${notification.claimId}`
              ) {
                this.$router.push(
                  `/${notification.pipelineSlug}/${notification.claimId}`
                )
                this.$store.dispatch('mtpl/getClaim', notification.claimId)
              }
            })
        } else {
          if (
            this.$router.currentRoute.fullPath !=
            `/${notification.pipelineSlug}/${notification.claimId}`
          ) {
            this.$router.push(
              `/${notification.pipelineSlug}/${notification.claimId}`
            )
            this.$store.dispatch('mtpl/getClaim', notification.claimId)
          }
        }
      } else {
        if (!notification.seenAt) {
          this.$store
            .dispatch('notification/markRead', notification.id)
            .then(() => {
              if (
                this.$router.currentRoute.fullPath !=
                `/${notification.pipelineSlug}/${notification.claimId}`
              ) {
                this.$router.push(
                  `/${notification.pipelineSlug}/${notification.claimId}`
                )
                this.$store.dispatch('mtpl/getClaim', notification.claimId)
              }
            })
        } else {
          if (
            this.$router.currentRoute.fullPath !=
            `/${notification.pipelineSlug}/${notification.claimId}`
          ) {
            this.$router.push(
              `/${notification.pipelineSlug}/${notification.claimId}`
            )
            this.$store.dispatch('mtpl/getClaim', notification.claimId)
          }
        }
      }
    },
    markUnread(notification) {
      this.$store.dispatch('notification/markUnread', notification.id)
    },
    markRead(notification) {
      this.$store.dispatch('notification/markRead', notification.id)
    },
    deleteNotification(notification) {
      this.$store.dispatch('notification/deleteNotification', notification.id)
    },
    toggleEdit() {
      if (this.edit) {
        this.selectedNotifications = []
      }
      this.edit = !this.edit
    },
    selectAllNotifications() {
      this.selectedNotifications = this.computedNotifications.map((n) => n.id)
    },
    deleteSelectedNotifications() {
      this.$store
        .dispatch(
          'notification/deleteSelectedNotifications',
          this.selectedNotifications
        )
        .then(() => {
          this.$nextTick(() => {
            this.selectedNotifications = []
            this.edit = false
          })
        })
    },
  },
  computed: {
    showDrawer: {
      get() {
        return this.drawerShow
      },
      set(value) {
        if (!this.drawerShow == value) {
          this.$emit('toggle', value)
        }
      },
    },
    computedNotifications() {
      return this.limit ? this.notifications.slice(0, 25) : this.notifications
    },
    ...mapGetters({
      notifications: 'notification/notifications',
    }),
  },
  created() {
    this.$store.dispatch('notification/getNotifications')
    this.$socket.client.open()
  },
  sockets: {
    connect() {
      this.$socket.client.emit(
        'authenticate',
        JSON.stringify(this.$store.getters['user/websocketAuthDetails'])
      )
    },

    notification() {
      this.$store.dispatch('notification/getNotifications')
      this.$store.dispatch('snackbar/addSnackbar', {
        text: `You have new notifications.`,
        color: 'success',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.notificationDrawer {
  margin-top: 64px;
  max-height: 50vh;
}

.small-checkbox {
  transform: scale(0.75); /* Adjust the scale to make the checkbox smaller */
}

.custom-icon-margin {
  align-items: center;
  margin-right: 0 !important;
}
</style>
