import dayjs from 'dayjs'

export const claimedAmountDataset = (array) => {
  let dataSet = array.map((el) => ({
    reportedDate: dayjs(el.reportedDate).add(1, 'month').format('YYYY-MM'),
    amount: el.amountClaimed,
  }))
  const dataFixedArray = {}
  dataSet.forEach((element) => {
    if (dataFixedArray[element.reportedDate] != null) {
      dataFixedArray[element.reportedDate] += element.amount
    } else {
      dataFixedArray[element.reportedDate] = element.amount
    }
  })

  const labels = []
  const data = []
  for (const key in dataFixedArray) {
    labels.push(key)
    data.push(dataFixedArray[key])
  }

  return dataFixedArray
}
