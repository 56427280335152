import axios from 'axios'
import dayjs from 'dayjs'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Version: process.env.VUE_APP_VERSION,
  },
})

export default {
  uploadFile(file, onUploadProgress) {
    let maxFileNameLength = 255
    let formData = new FormData()
    let filename = file.name
    if (file.name.length > maxFileNameLength && file.name.includes('.')) {
      let filenameArray = file.name.split('.')
      filename = filenameArray[0]
      let extension = filenameArray[1]
      filename =
        filename.substr(0, maxFileNameLength) +
        dayjs().format('YYYYMMDDHHmmssSSS') +
        '.' +
        extension
    }
    formData.append('file', file, filename)
    formData.append('category', file.category)
    return apiClient.post('/api/v1/files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  },
  getFile(fileId) {
    return apiClient.get(`/api/v1/files/${fileId}`)
  },
  patchFileCategory({ id, category }) {
    return apiClient.patch(`/api/v1/files/${id}`, { category })
  },
}
