import UtilityService from '../../services/UtilityService'

export const namespaced = true
export const state = {
  insuredPersons: [],
}
export const getters = {
  value: (state) => {
    return state.value
  },
  insuredPersonById: (state, id) => {
    return state.insuredPersons.find((insuredPerson) => insuredPerson.id == id)
  },
}
export const mutations = {
  SET_INSURED_PERSONS(state, insuredPersons) {
    state.insuredPersons = insuredPersons
  },
  CLEAR_INSURED_PERSONS(state) {
    state.insuredPersons = []
  },

  ADD_INSURED_PERSON(state, insuredPerson) {
    state.insuredPersons = [...state.insuredPersons, insuredPerson]
  },
  DO_NOTHING() {},
}
export const actions = {
  // updateValue({ commit }, payload) {
  //   commit('updateValue', payload)
  // },
  async getInsuredPersons({ commit }, clientId) {
    UtilityService.getInsuredPersons(clientId).then((response) => {
      commit('SET_INSURED_PERSONS', response.data.insuredPersons)
    })
  },
  async createInsuredPerson({ commit }, insuredPerson) {
    return UtilityService.addInsuredPerson(insuredPerson).then((response) => {
      commit('ADD_INSURED_PERSON', response.data.insuredPerson)
      return response.data.insuredPerson.id
    })
  },
  async deleteInsuredPerson({ dispatch }, payload) {
    return UtilityService.deleteInsuredPerson(payload).then(() => {
      dispatch('getInsuredPersons', payload.clientId)
    })
  },
  async updateInsuredPerson({ dispatch }, payload) {
    return UtilityService.updateInsuredPerson(payload).then(() => {
      dispatch('getInsuredPersons', payload.clientId)
    })
  },
  clearInsuredPersons({ commit }) {
    commit('CLEAR_INSURED_PERSONS')
  },
  //   async importDrivers({ commit }, payload) {
  //     return UtilityService.importDrivers(payload)
  //       .then((response) => {
  //         commit('DO_NOTHING')
  //         return response
  //       })
  //       .catch(() => {
  //         let snackbar = {
  //           text: `Could not import drivers.`,
  //           color: 'error',
  //         }
  //         this.$store.dispatch('snackbar/addSnackbar', snackbar, {
  //           root: true,
  //         })
  //       })
  //   },
}
