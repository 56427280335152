import UtilityService from '../../services/UtilityService'
import i18n from '@/plugins/i18n'

// const _ = undefined

export const namespaced = true
export const state = {
  sideBarLinks: [
    // {
    //   icon: 'mdi-monitor-dashboard',
    //   title: 'Dashboard',
    //   to: '/dashboard',
    // },
    {
      icon: 'mdi-account-group',
      title: i18n.t('title.user'),
      to: '/user',
    },
    {
      icon: 'mdi-domain',
      title: i18n.t('title.client'),
      to: '/client',
    },
    {
      icon: 'mdi-database',
      title: i18n.t('title.activityLog'),
      to: '/activitylog',
    },
    {
      icon: 'mdi-card-account-details-outline',
      title: i18n.t('title.driver'),
      to: '/driver',
    },
    {
      icon: 'mdi-truck',
      title: i18n.t('title.vehicle'),
      to: '/vehicle',
    },
    {
      icon: 'mdi-account-check',
      title: i18n.t('title.insuredPerson'),
      to: '/insuredperson',
    },
  ],
}
export const getters = {
  //TODO: how to skip function parameters
  /* eslint-disable */
  sideBarLinks: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */

    let links = []
    if (_rootState.pipeline.pipelines.length > 0) {
      rootGetters['user/checkPipeline']('CI') &&
        links.push({
          icon: 'mdi-text-box-outline',
          title: i18n.t('sideBarLinks.CI'),
          tooltip: i18n.t('sideBarLinksTooltips.CI'),
          to: '/cidashboard',
        })
      rootGetters['user/checkPipeline']('CMR') &&
        links.push({
          icon: 'mdi-text-box-outline',
          title: i18n.t('sideBarLinks.CMR'),
          tooltip: i18n.t('sideBarLinksTooltips.CMR'),
          to: '/cmrdashboard',
        })
      rootGetters['user/checkPipeline']('MTPL') &&
        links.push({
          icon: 'mdi-text-box-outline',
          title: i18n.t('sideBarLinks.MTPL'),
          tooltip: i18n.t('sideBarLinksTooltips.MTPL'),
          to: '/mtpldashboard',
        })
      rootGetters['user/checkPipeline']('MTPL Injured') &&
        links.push({
          icon: 'mdi-text-box-outline',
          title: i18n.t('sideBarLinks["MTPL Injured"]'),
          tooltip: i18n.t('sideBarLinksTooltips["MTPL Injured"]'),
          to: '/mtplinjureddashboard',
        })

      rootGetters['user/checkPipeline']('CASCO') &&
        links.push({
          icon: 'mdi-text-box-outline',
          title: i18n.t('sideBarLinks.CASCO'),
          tooltip: i18n.t('sideBarLinksTooltips.CASCO'),
          to: '/cascodashboard',
        })

      rootGetters['user/checkPipeline']('KRD/NA') &&
        links.push({
          icon: 'mdi-text-box-outline',
          title: i18n.t('sideBarLinks["KRD/NA"]'),
          tooltip: i18n.t('sideBarLinksTooltips["KRD/NA"]'),
          to: '/krdnadashboard',
        })

      rootGetters['user/checkPipeline']('CP') &&
        links.push({
          icon: 'mdi-text-box-outline',
          title: i18n.t('sideBarLinks.CP'),
          tooltip: i18n.t('sideBarLinksTooltips.CP'),
          to: '/cpdashboard',
        })

      rootGetters['user/checkPipeline']('BCA') &&
        links.push({
          icon: 'mdi-text-box-outline',
          title: i18n.t('sideBarLinks.BCA'),
          tooltip: i18n.t('sideBarLinksTooltips.BCA'),
          to: '/bcadashboard',
        })
    }

    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        links.push(
          ...[
            {
              icon: 'mdi-account-group',
              title: i18n.t('title.user'),
              to: '/user',
            },
            {
              icon: 'mdi-domain',
              title: i18n.t('title.client'),
              to: '/client',
            },
            {
              icon: 'mdi-database',
              title: i18n.t('title.activityLog'),
              to: '/activitylog',
            },
            {
              icon: 'mdi-card-account-details-outline',
              title: i18n.t('title.driver'),
              to: '/driver',
            },
            {
              icon: 'mdi-truck',
              title: i18n.t('title.vehicle'),
              to: '/vehicle',
            },
            {
              icon: 'mdi-account-check',
              title: i18n.t('title.insuredPerson'),
              to: '/insuredperson',
            },
          ]
        )
        break
      case 'account':
        links.push(
          ...[
            {
              icon: 'mdi-account-group',
              title: i18n.t('title.user'),
              to: '/user',
            },
            {
              icon: 'mdi-domain',
              title: i18n.t('title.client'),
              to: '/client',
            },
            {
              icon: 'mdi-card-account-details-outline',
              title: i18n.t('title.driver'),
              to: '/driver',
            },
            {
              icon: 'mdi-truck',
              title: i18n.t('title.vehicle'),
              to: '/vehicle',
            },
            {
              icon: 'mdi-account-check',
              title: i18n.t('title.insuredPerson'),
              to: '/insuredperson',
            },
          ]
        )
        break
      case 'client':
        links.push(
          ...[
            {
              icon: 'mdi-account-group',
              title: i18n.t('title.user'),
              to: '/user',
            },
            {
              icon: 'mdi-domain',
              title: i18n.t('title.client'),
              to: '/client',
            },
            {
              icon: 'mdi-card-account-details-outline',
              title: i18n.t('title.driver'),
              to: '/driver',
            },
            {
              icon: 'mdi-truck',
              title: i18n.t('title.vehicle'),
              to: '/vehicle',
            },
            {
              icon: 'mdi-account-check',
              title: i18n.t('title.insuredPerson'),
              to: '/insuredperson',
            },
          ]
        )
        break
      default:
        return []
    }
    return links
  },
  /* eslint-disable */
  links: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [
          // {
          //   label: 'MTPL',
          //   url: '/mtpldashboard',
          // },
        ]
      case 'account':
        return [
          // {
          //   label: 'MTPL',
          //   url: '/mtpldashboard',
          // },
        ]
      case 'client':
        return [
          // {
          //   label: 'MTPL',
          //   url: '/mtpldashboard',
          // },
        ]
      default:
        return []
    }
  },
  /* eslint-disable */
  MTPLDashboardTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [
          { id: 1, name: 'tabs.data', route: '/mtpldashboard' },
          // { id: 2, name: 'tabs.insight', route: '/mtpldashboard/insight' },
          { id: 3, name: 'tabs.import', route: '/mtpldashboard/import' },
        ]
      case 'account':
        return [
          { id: 1, name: 'tabs.data', route: '/mtpldashboard' },
          // { id: 2, name: 'tabs.insight', route: '/mtpldashboard/insight' },
          { id: 3, name: 'tabs.import', route: '/mtpldashboard/import' },
        ]
      case 'client':
        return [
          { id: 1, name: 'tabs.data', route: '/mtpldashboard' },
          // { id: 2, name: 'tabs.insight', route: '/mtpldashboard/insight' },
          { id: 3, name: 'tabs.import', route: '/mtpldashboard/import' },
        ]
      default:
        return []
    }
  },
  /* eslint-disable */
  CMRDashboardTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [{ id: 1, name: 'tabs.data', route: '/cmrdashboard' }]
      case 'account':
        return [{ id: 1, name: 'tabs.data', route: '/cmrdashboard' }]
      case 'client':
        return [{ id: 1, name: 'tabs.data', route: '/cmrdashboard' }]
      default:
        return []
    }
  },
  /* eslint-disable */
  CIDashboardTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [{ id: 1, name: 'tabs.data', route: '/cidashboard' }]
      case 'account':
        return [{ id: 1, name: 'tabs.data', route: '/cidashboard' }]
      case 'client':
        return [{ id: 1, name: 'tabs.data', route: '/cidashboard' }]
      default:
        return []
    }
  },
  /* eslint-disable */
  CASCODashboardTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [{ id: 1, name: 'tabs.data', route: '/cascodashboard' }]
      case 'account':
        return [{ id: 1, name: 'tabs.data', route: '/cascodashboard' }]
      case 'client':
        return [{ id: 1, name: 'tabs.data', route: '/cascodashboard' }]
      default:
        return []
    }
  },
  /* eslint-disable */
  MTPLInjuredDashboardTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [{ id: 1, name: 'tabs.data', route: '/mtplinjureddashboard' }]
      case 'account':
        return [{ id: 1, name: 'tabs.data', route: '/mtplinjureddashboard' }]
      case 'client':
        return [{ id: 1, name: 'tabs.data', route: '/mtplinjureddashboard' }]
      default:
        return []
    }
  },
  /* eslint-disable */
  KRDNADashboardTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [{ id: 1, name: 'tabs.data', route: '/krdnadashboard' }]
      case 'account':
        return [{ id: 1, name: 'tabs.data', route: '/krdnadashboard' }]
      case 'client':
        return [{ id: 1, name: 'tabs.data', route: '/krdnadashboard' }]
      default:
        return []
    }
  },
  /* eslint-disable */
  CPDashboardTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [{ id: 1, name: 'tabs.data', route: '/cpdashboard' }]
      case 'account':
        return [{ id: 1, name: 'tabs.data', route: '/cpdashboard' }]
      case 'client':
        return [{ id: 1, name: 'tabs.data', route: '/cpdashboard' }]
      default:
        return []
    }
  },
  /* eslint-disable */
  BCADashboardTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [{ id: 1, name: 'tabs.data', route: '/bcadashboard' }]
      case 'account':
        return [{ id: 1, name: 'tabs.data', route: '/bcadashboard' }]
      case 'client':
        return [{ id: 1, name: 'tabs.data', route: '/bcadashboard' }]
      default:
        return []
    }
  },
  /* eslint-disable */
  userTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [
          { id: 1, name: 'userTabs.profile', route: `/user` },
          {
            id: 2,
            name: 'userTabs.registration',
            route: `/user/registration`,
          },
          {
            id: 3,
            name: 'userTabs.userList',
            route: `/user/userlist`,
          },
        ]
      case 'account':
        return [
          { id: 1, name: 'userTabs.profile', route: `/user` },
          {
            id: 2,
            name: 'userTabs.registration',
            route: `/user/registration`,
          },
          {
            id: 3,
            name: 'userTabs.userList',
            route: `/user/userlist`,
          },
        ]
      case 'client':
        return [{ id: 1, name: 'userTabs.profile', route: `/user` }]
      default:
        return []
    }
  },
  vehicleTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [
          { id: 1, name: 'tabs.vehicle', route: `/vehicle` },
          {
            id: 2,
            name: 'tabs.vehicleImport',
            route: `/vehicle/import`,
          },
        ]
      case 'account':
        return [
          { id: 1, name: 'tabs.vehicle', route: `/vehicle` },
          {
            id: 2,
            name: 'tabs.vehicleImport',
            route: `/vehicle/import`,
          },
        ]
      case 'client':
        return [{ id: 1, name: 'tabs.vehicle', route: `/vehicle` }]
      default:
        return []
    }
  },
  driverTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [
          { id: 1, name: 'tabs.driver', route: `/driver` },
          {
            id: 2,
            name: 'tabs.driverImport',
            route: `/driver/import`,
          },
        ]
      case 'account':
        return [
          { id: 1, name: 'tabs.driver', route: `/driver` },
          {
            id: 2,
            name: 'tabs.driverImport',
            route: `/driver/import`,
          },
        ]
      case 'client':
        return [{ id: 1, name: 'tabs.driver', route: `/driver` }]
      default:
        return []
    }
  },
  insuredPersonTabs: (state, _getters, _rootState, rootGetters) => {
    /* eslint-enable */
    let role = rootGetters['user/role']
    switch (role) {
      case 'admin':
        return [
          { id: 1, name: 'tabs.insuredPerson', route: `/insuredperson` },
          // {
          //   id: 2,
          //   name: 'Insured Person import',
          //   route: `/insuredperson/import`,
          // },
        ]
      case 'account':
        return [
          { id: 1, name: 'tabs.insuredPerson', route: `/insuredperson` },
          // {
          //   id: 2,
          //   name: 'Insured Person import',
          //   route: `/insuredperson/import`,
          // },
        ]
      case 'client':
        return [{ id: 1, name: 'tabs.insuredPerson', route: `/insuredperson` }]
      default:
        return []
    }
  },
}
export const mutations = {
  SET_DRIVERS(state, drivers) {
    state.drivers = drivers
  },
  CLEAR_DRIVERS(state) {
    state.drivers = []
  },

  ADD_DRIVER(state, driver) {
    state.drivers = [...state.drivers, driver]
  },
}
export const actions = {
  // updateValue({ commit }, payload) {
  //   commit('updateValue', payload)
  // },
  async getDrivers({ commit }, clientId) {
    UtilityService.getDrivers(clientId).then((response) => {
      commit('SET_DRIVERS', response.data.drivers)
    })
  },
  async createDriver({ commit }, driver) {
    return UtilityService.addDriver(driver).then((response) => {
      commit('ADD_DRIVER', response.data.driver)
      return response.data.driver.id
    })
  },
  async deleteDriver({ dispatch }, payload) {
    return UtilityService.deleteDriver(payload).then(() => {
      dispatch('getDrivers', payload.clientId)
    })
  },
  async updateDriver({ dispatch }, payload) {
    return UtilityService.updateDriver(payload).then(() => {
      dispatch('getDrivers', payload.clientId)
    })
  },
  clearDrivers({ commit }) {
    commit('CLEAR_DRIVERS')
  },
}
