import CircumstanceService from '../../services/CircumstanceService'

export const namespaced = true
export const state = {
  circumstances: [],
}
export const getters = {}
export const mutations = {
  SET_CIRCUMSTANCES(state, circumstances) {
    circumstances.sort((a, b) => {
      if (a.name === 'Other' && b.name !== 'Other') {
        return 1 // Move 'Other' to the back
      } else if (a.name !== 'Other' && b.name === 'Other') {
        return -1 // Keep 'Other' before other names
      } else {
        return 0 // Maintain the current order
      }
    })
    state.circumstances = circumstances
  },
  CLEAR_CIRCUMSTANCES(state) {
    state.circumstances = []
  },
  DO_NOTHING() {},
}
export const actions = {
  async getCircumstances({ commit }, pipelineId) {
    CircumstanceService.getCircumstances(pipelineId).then((response) => {
      commit('SET_CIRCUMSTANCES', response.data.circumstances)
    })
  },
  clearCircumstances({ commit }) {
    commit('CLEAR_CIRCUMSTANCES')
  },
}
