<template>
  <div
    :class="
      value
        ? 'v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-input--selection-controls v-input--checkbox primary--text'
        : 'v-input v-input--dense theme--light v-input--selection-controls v-input--checkbox'
    "
  >
    <div class="v-input__control">
      <div class="v-input__slot">
        <div class="v-input--selection-controls__input">
          <i
            aria-hidden="true"
            :class="
              value
                ? 'v-icon notranslate v-icon--dense mdi mdi-checkbox-marked theme--light primary--text'
                : 'v-icon notranslate v-icon--dense mdi mdi-checkbox-blank-outline theme--light'
            "
            @click="changeCheckbox"
          ></i
          ><input
            class="fix--css--properties"
            :aria-checked="value ? 'true' : 'false'"
            role="checkbox"
            type="checkbox"
            :checked="value"
            :readonly="readonly"
            :disabled="disabled"
          />
          <div
            :class="
              value
                ? 'v-input--selection-controls__ripple primary--text'
                : 'v-input--selection-controls__ripple'
            "
            @click="changeCheckbox"
          ></div>
        </div>
        <label
          class="v-label theme--light"
          style="left: 0px; right: auto; position: relative; cursor: default"
          >{{ label }}</label
        >
      </div>
      <div
        :class="
          value
            ? 'v-messages theme--light primary--text'
            : 'v-messages theme--light'
        "
      >
        <div class="v-messages__wrapper"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { default: '' },
    value: { default: '' },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },

  methods: {
    changeCheckbox() {
      if (!this.readonly) this.$emit('input', !this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.fix--css--properties {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
</style>
