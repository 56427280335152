import FileService from './../../services/FileService'
export const namespaced = true
export const state = {
  files: [],
}
export const getters = {
  value: (state) => {
    return state.value
  },
}
export const mutations = {
  SET_FILES(state, files) {
    state.files = files
  },
  APPEND_FILE(state, file) {
    state.files = [...state.files, file]
  },
  CLEAR_FILES(state) {
    state.files = []
  },
  REMOVE_FILE(state, file) {
    state.files = state.files.filter((f) => f != file)
  },
  CHANGE_FILE(state, file) {
    state.files = state.files.filter((f) => f.id != file.id)
    state.files = [...state.files, file]
  },
}
export const actions = {
  // updateValue({ commit }, payload) {
  //   commit('updateValue', payload)
  // },
  async getFile({ commit }, fileId) {
    FileService.getFile(fileId).then((response) => {
      commit('APPEND_FILE', response.data.file)
    })
  },
  async getFiles({ dispatch }, filesIds) {
    filesIds.forEach((fileId) => {
      dispatch('getFile', fileId)
    })
  },
  async changeCategory({ commit }, fileData) {
    FileService.patchFileCategory(fileData).then((response) => {
      commit('CHANGE_FILE', response.data.file)
    })
  },
  removeFile({ commit }, file) {
    commit('REMOVE_FILE', file)
  },
  clearFiles({ commit }) {
    commit('CLEAR_FILES')
  },
}
