import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Version: process.env.VUE_APP_VERSION,
  },
})

export default {
  addReminder(data) {
    return apiClient.post(`/api/v1/reminders`, data)
  },
  getReminders() {
    return apiClient.get(`/api/v1/reminders`)
  },
  updateReminder(payload) {
    return apiClient.patch(`/api/v1/reminders/${payload.id}`, payload)
  },
  deleteReminder(reminderId) {
    return apiClient.delete(`/api/v1/reminders/${reminderId}`)
  },
}
