<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <!-- <h1>ClientDashboard</h1> -->
        <v-container>
          <v-row>
            <v-col>
              <StatsCard
                label="Paid:"
                :value="paidAmount"
                color="#fb8500"
                icon="mdi-history"
                :loading="isLoading"
              />
            </v-col>
            <v-col>
              <StatsCard
                label="Total:"
                :value="claims.length"
                color="#723d46"
                icon="mdi-text-box-multiple"
                :loading="isLoading"
              />
            </v-col>
            <v-col>
              <StatsCard
                label="Closed:"
                :value="closedClaims.length"
                color="#4CAF50"
                icon="mdi-clipboard-check-multiple"
                :loading="isLoading"
              />
            </v-col>
            <v-col>
              <StatsCard
                label="Active:"
                :value="activeClaims"
                color="#ffc300"
                icon="mdi-timer"
                :loading="isLoading"
              />
            </v-col>
          </v-row>
          <MultiMap />
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { claimedAmountDataset } from './../utils/dataManipulation'
import StatsCard from './../components/StatsCard.vue'
import MultiMap from './../components/MultiMap.vue'

import { mapState } from 'vuex'
export default {
  components: {
    StatsCard,
    MultiMap,
  },
  data() {
    return {
      isLoading: true,
      paidAmount: 0,
      closedClaims: 0,
      claims: [],
    }
  },
  computed: {
    ...mapState({
      mtpls: (state) => state.mtpl.mtpls,
    }),
    activeClaims: function () {
      return this.claims?.length - this.closedClaims?.length || 0
    },
    barChartDataset: function () {
      return claimedAmountDataset(this.closedClaims)
    },
  },
  created() {
    this.$store.dispatch('mtpl/getClaims', {
      pipelineId: 1,
      orderBy: 'reportedDate',
    })
  },
  watch: {
    mtpls() {
      this.claims = this.mtpls?.claims
      this.paidAmount = ~~(this.calculatePaidAmount() / 100)
      this.closedClaims = this.mtpls?.claims?.filter(
        (c) =>
          c.stage.displayName == 'Paid/Closed' ||
          c.stage.displayName == 'Reported by insurer' ||
          c.stage.displayName == 'Agreed, but unpaid'
      )
      this.isLoading = false
    },
  },
  methods: {
    calculatePaidAmount() {
      return this.mtpls.claims
        ?.map((c) => c.amountPaid)
        .reduce((p, c) => p + c, 0)
    },
  },
}
</script>

<style lang="scss" scoped></style>
