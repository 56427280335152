import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Version: process.env.VUE_APP_VERSION,
  },
})

export default {
  getMessages(claimId) {
    return apiClient.get(`/api/v1/claims/${claimId}/messages?pageSize=1000`)
  },
  sendMessage(claimId, message) {
    return apiClient.post(`/api/v1/claims/${claimId}/messages`, {
      message: message,
    })
  },
  deleteMessage(messageId) {
    return apiClient.delete(`/api/v1/messages/${messageId}`)
  },
}
