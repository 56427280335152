import UtilityService from '../../services/UtilityService'

export const namespaced = true
export const state = {
  drivers: [],
}
export const getters = {
  value: (state) => {
    return state.value
  },
}
export const mutations = {
  SET_DRIVERS(state, drivers) {
    state.drivers = drivers
  },
  CLEAR_DRIVERS(state) {
    state.drivers = []
  },

  ADD_DRIVER(state, driver) {
    state.drivers = [...state.drivers, driver]
  },
  DO_NOTHING() {},
}
export const actions = {
  // updateValue({ commit }, payload) {
  //   commit('updateValue', payload)
  // },
  async getDrivers({ commit }, clientId) {
    UtilityService.getDrivers(clientId).then((response) => {
      commit('SET_DRIVERS', response.data.drivers)
    })
  },
  async createDriver({ commit }, driver) {
    return UtilityService.addDriver(driver).then((response) => {
      commit('ADD_DRIVER', response.data.driver)
      return response.data.driver.id
    })
  },
  async deleteDriver({ dispatch }, payload) {
    return UtilityService.deleteDriver(payload).then(() => {
      dispatch('getDrivers', payload.clientId)
    })
  },
  async updateDriver({ dispatch }, payload) {
    return UtilityService.updateDriver(payload).then(() => {
      dispatch('getDrivers', payload.clientId)
    })
  },
  clearDrivers({ commit }) {
    commit('CLEAR_DRIVERS')
  },
  async importDrivers({ commit }, payload) {
    return UtilityService.importDrivers(payload)
      .then((response) => {
        commit('DO_NOTHING')
        return response
      })
      .catch(() => {
        let snackbar = {
          text: `${this.$i18n.t('snackbar.couldNotImportDrivers')}.`,
          color: 'error',
        }
        this.$store.dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
  },
}
