import ActivityLogService from '../../services/ActivityLogService'

export const namespaced = true
export const state = {
  activities: [],
}
export const getters = {
  value: (state) => {
    return state.value
  },
}
export const mutations = {
  SET_ACTIVITIES(state, activities) {
    state.activities = activities
  },
  CLEAR_ACTIVITIES(state) {
    state.activities = []
  },
}
export const actions = {
  updateValue({ commit }, payload) {
    commit('updateValue', payload)
  },
  async getActivities({ commit }) {
    ActivityLogService.getActivities().then((response) => {
      commit('SET_ACTIVITIES', response.data.activities)
    })
  },
  clearActivities({ commit }) {
    commit('CLEAR_ACTIVITIES')
  },
}
