<template>
  <div>
    <Snackbar
      v-for="snackbar in snackbars"
      :snackbar="snackbar"
      :key="snackbar.id"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Snackbar from './Snackbar'
export default {
  components: {
    Snackbar,
  },
  computed: {
    ...mapState({
      snackbars: (state) => state.snackbar.snackbars,
    }),
  },
}
</script>

<style lang="scss" scoped></style>
