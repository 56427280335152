export const namespaced = true
export const state = {
  snackbars: [],
}
export const getters = {
  value: (state) => {
    return state.value
  },
}

let nextId = 1
export const mutations = {
  ADD_SNACKBAR(state, snackbar) {
    state.snackbars.push({
      ...snackbar,
      id: nextId++,
    })
  },
  REMOVE_SNACKBAR(state, snackbarToRemove) {
    state.snackbars = state.snackbars.filter(
      (s) => s.id !== snackbarToRemove.id
    )
  },
}
export const actions = {
  addSnackbar({ commit }, snackbar) {
    commit('ADD_SNACKBAR', snackbar)
  },
  remove({ commit }, snackbar) {
    commit('REMOVE_SNACKBAR', snackbar)
  },
}
