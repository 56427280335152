import axios from 'axios'
import router from '@/router/index'
import UserService from '../../services/UserService'
import i18n from '@/plugins/i18n'

// const baseURI = process.env.VUE_APP_API_BASE_URL

export const namespaced = true

axios.defaults.withCredentials = true

export const state = {
  user: null,
  impersonator: null,
  profile: null,
  users: [],
}

export const mutations = {
  SET_USER_DATA(state, data) {
    state.user = data
    localStorage.setItem('user', JSON.stringify(data))
    // axios.defaults.headers.common['Authorization'] = data.token
    // state.token = data.token
  },
  CLEAR_USER_DATA() {
    localStorage.removeItem('user')
    location.reload()
  },
  SET_USER_PROFILE(state, { user: profile, impersonator = null, user }) {
    state = Object.assign(state, { profile, impersonator, user })
  },
  UPDATE_PROFILE_DATA(state, { user }) {
    state.profile = user
  },
  UPDATE_USERS(state, data) {
    const newState = Object.assign([], state.users)
    let index = newState.findIndex((u) => (u.id = data.id))
    newState[index] = data
    state.users = newState
  },
  SET_USERS(state, data) {
    state.users = data
  },
  CLEAR_USERS(state) {
    state.users = []
  },
  DO_NOTHING() {},
}

export const actions = {
  async login({ commit, dispatch }, credentials) {
    return UserService.login(credentials)
      .then((response) => {
        let { data } = response
        commit('SET_USER_DATA', data.user)
        router.push({ name: 'Home' })
      })
      .catch((err) => {
        if (err.response) {
          const snackbar = {
            color: 'error',
            text: i18n.t('snackbar.failedToLogin'),
          }
          dispatch('snackbar/addSnackbar', snackbar, { root: true })
        }
      })
  },
  logout({ commit }) {
    commit('CLEAR_USER_DATA')
    router.push({ name: 'Login' })
  },
  resetCookies() {
    localStorage.removeItem('cookie:accepted')
    router.push({ name: 'Login' })
  },
  async getProfile({ commit, dispatch }) {
    UserService.getProfile()
      .then((response) => {
        commit('SET_USER_PROFILE', response.data)
      })
      .catch((err) => {
        if (err.response?.data?.message == 'unauthorized') {
          dispatch('user/logout', null, { root: true })
        }
      })
  },
  async updateProfile({ commit }, data) {
    UserService.updateProfile(data).then((response) => {
      commit('UPDATE_PROFILE_DATA', response.data)
    })
  },

  async impersonateUser({ dispatch }, userId) {
    UserService.impersonateUser(userId).then(() => {
      // commit('UPDATE_PROFILE_DATA', response.data.user)
      router.push('/')
      dispatch('getProfile')
    })
  },

  async cancelImpersonation({ dispatch }) {
    UserService.cancelImpersonation().then(() => {
      // commit('UPDATE_PROFILE_DATA', response.data.user)
      router.go()
      dispatch('getProfile')
    })
  },
  async updateUser({ dispatch }, payload) {
    UserService.updateUser(payload).then(() => {
      // commit('UPDATE_USERS', response.data.user)
      dispatch('getUsers')
    })
  },
  async changePassword({ dispatch }, payload) {
    UserService.changePassword(payload).then(() => {
      let snackbar = {
        text: `${i18n.t('snackbar.passwordSetSuccessfully')}. ${i18n.t(
          'snackbar.loginWithNewPassword'
        )}.`,
        color: 'success',
      }
      dispatch('snackbar/addSnackbar', snackbar, { root: true })
      router.push('/login')
    })
  },
  async resetPassword({ dispatch }, payload) {
    UserService.resetPassword(payload)
      .then(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.passwordResetRequestSuccessful')}. ${
            payload.email
          }`,
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
      })
      .catch(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.passwordResetRequestUnsuccessful')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
      })
  },
  async getUsers({ commit }) {
    UserService.getUsers().then((response) => {
      commit('SET_USERS', response.data.users)
    })
  },
  clearUsers({ commit }) {
    commit('CLEAR_USERS')
  },
}

export const getters = {
  //Checks if user has pipeline permissions by given pipeline name.
  checkPipeline: (state, getters, rootState, rootGetters) => (name) => {
    let pipelineId = rootGetters['pipeline/pipelineIdByName'](name)

    let result = state.user?.pipelines
      .map((p) => p.pipelineId)
      .includes(pipelineId)
    return result
  },
  loggedIn(state) {
    return !!state.user
  },
  username(state) {
    return `${state.user?.firstName} ${state.user?.lastName}`
  },
  role(state) {
    return state.user ? state.user.role : null
  },
  user(state) {
    return state.user
  },
  websocketAuthDetails(state) {
    return {
      userId: state.user.id,
      token: state.user.socketToken,
      timestamp: state.user.sessionTimestamp,
    }
  },
  impersonator(state) {
    return state.impersonator
  },
}
