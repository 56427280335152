import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Version: process.env.VUE_APP_VERSION,
  },
})

export default {
  getNotifications() {
    return apiClient.get(`/api/v1/notifications?pageSize=1000`)
  },
  markRead(payload) {
    return apiClient.put(`/api/v1/notifications/read`, payload)
  },
  markUnread(notificationId) {
    return apiClient.put(`/api/v1/notifications/${notificationId}/unread`)
  },
  deleteNotification(notificationId) {
    return apiClient.delete(`/api/v1/notifications/${notificationId}`)
  },
}
