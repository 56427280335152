import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import * as mtpl from './modules/mtpl'
import * as user from './modules/user'
import * as message from './modules/message'
import * as snackbar from './modules/snackbar'
import * as activity from './modules/activity'
import * as client from './modules/client'
import * as vehicle from './modules/vehicle'
import * as driver from './modules/driver'
import * as file from './modules/file'
import * as stage from './modules/stage'
import * as permission from './modules/permission'
import * as reminder from './modules/reminder'
import * as notification from './modules/notification'
import * as pipeline from './modules/pipeline'
import * as circumstance from './modules/circumstance'
import * as insuredPerson from './modules/insuredPerson'
import * as site from './modules/site'

export default new Vuex.Store({
  modules: {
    mtpl,
    user,
    message,
    snackbar,
    activity,
    client,
    vehicle,
    driver,
    file,
    stage,
    permission,
    reminder,
    notification,
    pipeline,
    circumstance,
    insuredPerson,
    site,
  },
  state: {},
  mutations: {},
  actions: {},
})
