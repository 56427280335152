import UtilityService from '../../services/UtilityService'
import i18n from '@/plugins/i18n'

export const namespaced = true
export const state = {
  vehicles: [],
}
export const getters = {
  value: (state) => {
    return state.value
  },
}
export const mutations = {
  SET_VEHICLES(state, vehicles) {
    state.vehicles = vehicles
  },
  CLEAR_VEHICLES(state) {
    state.vehicles = []
  },
  ADD_VEHICLE(state, vehicle) {
    state.vehicles = [...state.vehicles, vehicle]
  },
  DO_NOTHING() {},
}
export const actions = {
  // updateValue({ commit }, payload) {
  //   commit('updateValue', payload)
  // },
  async getVehicles({ commit }, clientId) {
    UtilityService.getVehicles(clientId).then((response) => {
      commit('SET_VEHICLES', response.data.vehicles)
    })
  },
  async createVehicle({ commit, dispatch }, vehicle) {
    return UtilityService.addVehicle(vehicle)
      .then((response) => {
        commit('ADD_VEHICLE', response.data.vehicle)
        return response.data.vehicle.id
      })
      .catch(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.couldNotCreateVehicle')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
  },
  async deleteVehicle({ dispatch }, payload) {
    return UtilityService.deleteVehicle(payload).then(() => {
      dispatch('getVehicles', payload.clientId)
    })
  },
  async updateVehicle({ dispatch }, payload) {
    return UtilityService.updateVehicle(payload).then(() => {
      dispatch('getVehicles', payload.clientId)
    })
  },
  async importVehicles({ commit }, payload) {
    return UtilityService.importVehicles(payload)
      .then((response) => {
        commit('DO_NOTHING')
        return response
      })
      .catch(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.couldNotImportVehicles')}.`,
          color: 'error',
        }
        this.$store.dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
  },
  clearVehicles({ commit }) {
    commit('CLEAR_VEHICLES')
  },
}
