<template>
  <v-container>
    <v-row>
      <v-col offset="3" cols="4" sm="4">
        <v-autocomplete
          v-if="clients"
          v-model="formData.clientId"
          :items="selectClients"
          @change="selectClient"
          value="formData.cliendId"
          :label="$t('wizard.selectCompany')"
          clearable
        ></v-autocomplete>
      </v-col> </v-row
    ><v-row>
      <v-col offset="3" cols="3" sm="3">
        <GmapMap
          ref="mapRef"
          :center="center"
          :zoom="7"
          :options="{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUI: false,
          }"
          map-type-id="terrain"
          style="width: 500px; height: 300px"
        >
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            @click="center = m.position"
          />
        </GmapMap>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      key: '',
      options: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
      selectClients: [],
      filteredClaims: [],
      formData: {
        clientId: null,
      },
      markers: [],
      center: { lat: 55.4281113, lng: 24.1197816 },
    }
  },
  methods: {
    selectClient(value) {
      this.formData.clientId = value
      this.$store.dispatch('mtpl/getClaimsByClient', this.formData.clientId)
    },
    fitBounds: function () {
      return this.$refs.mapRef.$mapPromise.then(() => {
        if (this.markers.length > 1) {
          const bounds = new window.google.maps.LatLngBounds()
          this.markers.forEach(function (marker) {
            bounds.extend(marker.position)
          })
          this.$refs.mapRef.fitBounds(bounds)
          return bounds
        }
      })
    },
  },
  mounted() {
    this.$store.dispatch('client/getClients')
  },
  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
      claims: (state) => state.mtpl.claims,
    }),
  },
  watch: {
    clients() {
      this.selectClients = this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
    claims() {
      this.markers = this.claims
        .filter((c) => c.incidentLatitude && c.incidentLongitude)
        .map((c) => ({
          position: {
            lat: c.incidentLatitude,
            lng: c.incidentLongitude,
          },
        }))
      if (this.markers.length > 0) this.fitBounds()
    },
  },
  markers() {},
}
</script>

<style lang="scss" scoped></style>
