export const namespaced = true
export const state = {
  preventNavigation: {
    prevent: false,
    text: null,
  },
}
export const getters = {
  value: (state) => {
    return state.value
  },
}
export const mutations = {
  SET_PREVENT_NAVIGATION(state, data) {
    state.preventNavigation = data
  },
}
export const actions = {
  preventNavigation({ commit }, text) {
    const data = {
      prevent: false,
      text: null,
    }
    if (text) {
      data.prevent = true
      data.text = text
      window.onbeforeunload = (e) => {
        e.returnValue = text
        return text
      }
    } else {
      window.onbeforeunload = null
    }
    commit('SET_PREVENT_NAVIGATION', data)
  },
}
