<template>
  <v-app>
    <!-- Must have the app property -->
    <NotificationBar
      v-if="loggedIn"
      :drawerShow="notificationDrawer"
      @toggle="toggleNavigationDrawers"
    />

    <v-navigation-drawer app v-model="drawer" class="no-print">
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <!-- <v-img src="https://randomuser.me/api/portraits/men/35.jpg"></v-img> -->
        </v-list-item-avatar>

        <v-list-item-title>{{ username }}</v-list-item-title>

        <v-btn icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in sideBarLinks"
          :key="item.title"
          link
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title
                  v-bind="attrs"
                  v-on="item.tooltip ? on : ''"
                  >{{ item.title }}</v-list-item-title
                >
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list class="claim-registration-list" dense rounded>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('buttons.claimRegistration') }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="item in fabItems">
            <v-list-item
              v-if="checkPipeline(item.label)"
              :key="item.label"
              :to="item.to"
              dense
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title
                    v-bind="attrs"
                    v-on="item.tooltip ? on : ''"
                    class="text-left caption"
                    >{{ item.title }}</v-list-item-title
                  >
                </template>
                <span>{{ item.tooltip }}</span>
              </v-tooltip>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar elevation="4" app color="primary" dark class="no-print">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-avatar class="isLink" @click="redirectToHome">
        <img :src="require('./assets/logo.svg')" alt="logo" />
      </v-avatar>
      <v-toolbar-title class="isLink" @click="redirectToHome">
        {{ $t('title.hive') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text rounded to="/calendar" v-if="!isClient">
        <v-icon>mdi-calendar-month-outline</v-icon>
      </v-btn>
      <v-btn text rounded @click="toggleNavigationDrawers">
        <v-badge :content="unseen">
          <v-icon>mdi-bell </v-icon>
        </v-badge>
      </v-btn>

      <v-btn
        text
        rounded
        :to="link.url"
        v-for="link in links"
        :key="`${link.label}-header-link`"
        >{{ link.label }}</v-btn
      >

      <v-btn text rounded v-if="!loggedIn" to="/login">{{
        $t('app.login')
      }}</v-btn>
      <v-btn text rounded v-else @click="logout">{{ $t('app.logout') }}</v-btn>
      <v-divider vertical></v-divider>

      <div class="language-bar-container">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on">
              <!-- <flag :iso="getFlagIso" v-bind:squared="false" /> -->
              {{ currentLocale().toUpperCase() }}
            </button>
          </template>
          <v-list>
            <v-list-item
              v-for="entry in languages"
              :key="entry.title"
              class="lang-menu-item"
              @click="changeLocale(entry.language)"
            >
              <!-- <button
                v-for="entry in languages"
                :key="entry.title"
                @click="changeLocale(entry.language)"
              > -->
              {{ entry.language.toUpperCase() }}
              <!-- <flag :iso="entry.flag" v-bind:squared="false" /> -->
              <!-- </button> -->
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- <v-btn text rounded to="/login">Login</v-btn> -->
    </v-app-bar>

    <v-main>
      <ImpersonationBar v-if="$store.getters['user/impersonator']" />
      <router-view />

      <v-snackbar v-model="snackWithButtons" bottom timeout="-1">
        {{ snackWithBtnText }}
        <template v-slot:action="{ attrs }">
          <v-btn text color="#00f500" v-bind="attrs" @click.stop="refreshApp">
            {{ snackBtnText }}
          </v-btn>
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackWithButtons = false"
          >
            X
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>

    <SnackbarContainer />
    <v-footer color="primary" padless>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="link in links"
          :key="`${link.label}-footer-link`"
          color="white"
          text
          rounded
          class="my-2"
          :to="link.url"
        >
          {{ link.label }}
        </v-btn>
        <!-- <v-col class="primary lighten-2 py-4 text-center white--text" cols="12">
          <img
            :src="require('./assets/footer_logo.svg')"
            alt="Footer logo"
            height="52"
          />
        </v-col> -->
        <cookie-law
          theme="blood-orange"
          buttonText="Accept"
          buttonClass="Cookie__button success"
          :buttonDecline="true"
          buttonDeclineClass="error Cookie__button"
          buttonDeclineText="Decline"
          position="bottom"
          transitionName="fade"
        >
          <div slot="message">
            We use cookies to enable the proper functioning and security of our
            websites, and help us offer you the best possible user experience.
            By clicking Accept, you consent to the use of these cookies for
            advertising and analytics. You can change your cookie settings at
            any time. For more information, please read our
            <router-link to="/cookie-policy">{{
              $t('app.cookiePolicy')
            }}</router-link
            >.
          </div>
        </cookie-law>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { authComputed } from './store/helpers.js'
import { mapGetters } from 'vuex'
import SnackbarContainer from './components/SnackbarContainer'
import NotificationBar from './components/NotificationBar'
import ImpersonationBar from './components/ImpersonationBar'
import CookieLaw from 'vue-cookie-law'
import i18n from '@/plugins/i18n'

import axios from 'axios'
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      this.$store.dispatch('user/logout')
    }
    return Promise.reject(error)
  }
)
export default {
  name: 'App',
  components: {
    SnackbarContainer,
    NotificationBar,
    ImpersonationBar,
    CookieLaw,
  },

  data: () => ({
    drawer: null,
    notificationDrawer: false,
    unseenNotificationCount: '',
    direction: 'top',
    fab: false,
    fling: false,
    hover: true,
    tabs: null,
    leftPosition: 60,
    top: false,
    right: false,
    bottom: true,
    left: true,
    fixed: true,
    absolute: true,
    transition: 'slide-y-reverse-transition',
    // App refresh snackbar
    refreshing: false,
    registration: null,
    snackBtnText: '',
    snackWithBtnText: '',
    snackWithButtons: false,

    // currentLocale: localStorage.getItem('lang') || 'en',
    // End of App refresh snackbar
    languages: [
      { flag: 'us', language: 'en', title: 'English' },
      { flag: 'lt', language: 'lt', title: 'Lietuvių' },
    ],
  }),
  computed: {
    ...mapGetters({
      checkPipeline: 'user/checkPipeline',
    }),
    ...authComputed,
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
    getFlagIso() {
      if (this.$i18n.locale === 'en') {
        return 'us'
      } else {
        return 'lt'
      }
    },
    fabItems() {
      return [
        {
          to: '/CIWizard',
          label: 'CI',
          title: i18n.t('sideBarLinks.CI'),
          tooltip: i18n.t('sideBarLinksTooltips.CI'),
        },
        {
          to: '/CMRWizard',
          label: 'CMR',
          title: i18n.t('sideBarLinks.CMR'),
          tooltip: i18n.t('sideBarLinksTooltips.CMR'),
        },
        {
          to: '/MTPLWizard',
          label: 'MTPL',
          title: i18n.t('sideBarLinks.MTPL'),
          tooltip: i18n.t('sideBarLinksTooltips.MTPL'),
        },
        {
          to: '/MTPLInjuredWizard',
          label: 'MTPL Injured',
          title: i18n.t('sideBarLinks["MTPL Injured"]'),
          tooltip: i18n.t('sideBarLinksTooltips["MTPL Injured"]'),
        },
        {
          to: '/CASCOWizard',
          label: 'CASCO',
          title: i18n.t('sideBarLinks.CASCO'),
          tooltip: i18n.t('sideBarLinksTooltips.CASCO'),
        },
        {
          to: '/KRDNAWizard',
          label: 'KRD/NA',
          title: i18n.t('sideBarLinks["KRD/NA"]'),
          tooltip: i18n.t('sideBarLinksTooltips["KRD/NA"]'),
        },
        {
          to: '/CPWizard',
          label: 'CP',
          title: i18n.t('sideBarLinks.CP'),
          tooltip: i18n.t('sideBarLinksTooltips.CP'),
        },
        {
          to: '/BCAWizard',
          label: 'BCA',
          title: i18n.t('sideBarLinks.BCA'),
          tooltip: i18n.t('sideBarLinksTooltips.BCA'),
        },
      ]
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
    },
    toggleNavigationDrawers() {
      this.notificationDrawer = !this.notificationDrawer
    },
    redirectToHome() {
      if (this.$router.currentRoute.fullPath != '/') {
        this.$router.push('/')
      }
    },
    changeLocale(locale) {
      i18n.locale = locale
      this.$vuetify.lang.current = locale
      localStorage.setItem('lang', locale)
    },
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail
      this.snackBtnText = 'Refresh'
      this.snackWithBtnText = 'New version available!'
      this.snackWithButtons = true
    },

    refreshApp() {
      this.snackWithButtons = false
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return
      }
      this.registration.waiting.postMessage('skipWaiting')
    },

    currentLocale() {
      return localStorage.getItem('lang') || 'en'
    },
  },
  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('user/SET_USER_DATA', userData)
      this.$store.dispatch('user/getProfile')
      this.$store.dispatch('stage/getAllStages')
      this.$store.dispatch('pipeline/getPipelines')
    }
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }
  },
  mounted() {},
}
</script>

<style>
/* Report generation CSS */
@media print {
  .no-print {
    display: none;
  }

  .v-main {
    padding: 0;
  }
}
@font-face {
  font-family: 'Bulo Bold';
  src: local('Bulo Bold'),
    url('./assets/fonts/Bulo Bold.woff') format('truetype');
}
#app {
  font-family: 'Bulo Bold', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.v-app-bar.v-app-bar--fixed {
  z-index: 999 !important;
}

.isLink:hover {
  cursor: pointer;
}

.v-card {
  font-family: 'Bulo Bold', Helvetica, Arial, sans-serif;
}

.v-speed-dial--right {
  right: 90px;
}

.language-bar-container {
  margin: 0 30px;
}

.claim-registration-list .v-list-group__header {
  background: #ec7b29;
  color: #ffffff;
  padding: 0;
}
.claim-registration-list i {
  color: #ffffff !important;
}

.claim-registration-list .v-list-group__header .v-list-item__title {
  color: #ffffff;
}

.claim-registration-list .v-list-item--dense {
  min-height: 25px;
}
</style>
