<template>
  <div>
    <span
      >{{ $t('impersonationBar.youAreLoggedInAs') }}
      <b>{{ impersonator.firstName }} {{ impersonator.lastName }}</b>
      {{ $t('impersonationBar.andCurrentlyImpersonating') }}
      <b>{{ profile.firstName }} {{ profile.lastName }}</b
      >.
    </span>
    <v-btn outlined x-small @click="$store.dispatch('user/cancelImpersonation')"
      >Cancel</v-btn
    >
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
      impersonator: (state) => state.user.impersonator,
    }),
  },
}
</script>

<style lang="scss" scoped>
.v-btn {
  color: #ec7b29;
}
</style>
