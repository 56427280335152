import ReminderService from '../../services/ReminderService'
import i18n from '@/plugins/i18n'

export const namespaced = true
export const state = {
  reminders: [],
}
export const getters = {
  value: (state) => {
    return state.value
  },
}
export const mutations = {
  SET_REMINDERS(state, reminders) {
    state.reminders = reminders
  },
  CLEAR_REMINDERS(state) {
    state.reminders = []
  },
  REMOVE_REMINDER(state, reminderId) {
    state.reminders = state.reminders.filter(
      (reminder) => reminder.id != reminderId
    )
  },
  UPDATE_REMINDER(state, reminder) {
    state.reminders = state.reminders.map((r) => {
      if (r.id == reminder.id) {
        return {
          ...reminder,
        }
      }
      return r
    })
  },
  DO_NOTHING() {},
}
export const actions = {
  async getReminders({ commit }) {
    ReminderService.getReminders().then((response) => {
      commit('SET_REMINDERS', response.data.reminders)
    })
  },
  async createReminder({ commit, dispatch }, data) {
    return ReminderService.addReminder(data)
      .then(function (response) {
        let snackbar = {
          text: `${i18n.t('snackbar.reminderFor')} ${
            response.data.reminder.message
          } ${i18n.t('snackbar.createdSuccessfully')}.`,
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
        commit('DO_NOTHING')
      })
      .catch(function () {
        let snackbar = {
          text: `${i18n.t('snackbar.reminderWasNotCreated')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
      })
  },
  async deleteReminder({ commit, dispatch }, reminderId) {
    return ReminderService.deleteReminder(reminderId)
      .then((response) => {
        let snackbar = {
          text: i18n.t('snackbar.reminderDeletedSuccessfully'),
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
        commit('REMOVE_REMINDER', response.data.reminder.id)
      })
      .catch(() => {
        let snackbar = {
          text: i18n.t('snackbar.reminderWasNotDeleted'),
          color: 'error',
        }
        dispatch('snackbar/addSnackBar', snackbar, { root: true })
      })
  },
  async updateReminder({ commit, dispatch }, payload) {
    return ReminderService.updateReminder(payload)
      .then(function (response) {
        let snackbar = {
          text: i18n.t('snackbar.reminderUpdatedSuccessfully'),
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
        commit('UPDATE_REMINDER', response.data.reminder)
        return response.data.reminder
      })
      .catch(function () {
        let snackbar = {
          text: i18n.t('snackbar.reminderWasNotUpdated'),
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
      })
  },
  clearReminders({ commit }) {
    commit('CLEAR_REMINDERS')
  },
}
