export const lt = {
  app: {
    login: 'Prisijungti',
    logout: 'Atsijungti',
    cookiePolicy: 'Slapukų politika',
  },
  userTabs: {
    profile: 'Vartotojo profilis',
    registration: 'Registracija',
    userList: 'Vartotojų sąrašas',
  },
  userRegistration: {
    firstName: 'Vardas',
    lastName: 'Pavardė',
    email: 'El. paštas',
    phone: 'Telefono nr.',
    selectRole: 'Pasirinkite rolę',
    selectCompany: 'Pasirinkite įmonę',
    selectPipelines: 'Pasirinkite žalų tipus',
    register: 'Registruoti',
    resetForm: 'Išvalyti formą',
    password: 'Slaptažodis',
    repeatPassword: 'Pakartokite slaptažodį',
    forgotPassword: 'Pamiršau slaptažodį',
    setPassword: 'Nustatyti slaptažodį',
  },
  userProfile: {
    firstName: 'Vardas',
    lastName: 'Pavardė',
    phone: 'Telefono nr.',
    save: 'Išsaugoti',
    changePassword: 'Pakeisti slaptažodį',
    passwordReset: 'Slaptažodžio atkūrimas',
    changesRegisteredSuccessfully: 'Pakeitimai sėkmingai užregistruoti',
  },
  //Section English translations in claim wizards.
  wizard: {
    //Section labels
    general: 'Pagrindinė informacija',
    location: 'Lokacija',
    incidentDetails: 'Papildoma informacija',
    documents: 'Dokumentai',
    //Steps
    steps: {
      general: 'Pagrindinė informacija',
      location: 'Lokacija',
      details: 'Papildoma informacija',
      documents: 'Dokumentai',
    },
    //Bottom wizard buttons
    backToClaimList: 'Grįžti į įvykių sąrašą',
    saveChanges: 'Išsaugoti pakeitimus',
    submit: 'Registruoti',
    previous: 'Ankstesnis',
    next: 'Toliau',
    //Field labels
    selectCompany: 'Pasirinkti įmonę',
    claimId: 'Žalos ID',
    createNewClient: 'Kurti naują klientą',
    claimNumber: 'Žalos numeris',
    clientClaimNumber: 'Kliento žalos Nr.',
    incidentDate: 'Įvykio data',
    incidentTime: 'Įvykio laikas',
    insuredVehicle: 'Apdrausta transporto priemonė',
    createNewVehicle: 'Kurti naują transporto priemonę',
    driver: 'Vairuotojas',
    createNewDriver: 'Kurti naują vairuotoją',
    reportedDate: 'Pranešimo data',
    amountClaimed: 'Pirminė prašoma suma',
    amountPaid: 'Galutinė sumokėta suma',
    insuranceClaimNumber: 'Draudimo žalos numeris',
    outcome: 'Bylos eiga',
    reasonForFailure: 'Nesutarimo priežastis',
    incidentLocation: 'Įvykio vieta',
    latitude: 'Platuma',
    longitude: 'Ilguma',
    getCurrentLocation: 'Gauti esamą lokaciją',
    selectDamagedPropertyType: 'Pasirinkti apgadinto turto tipą:',
    incidentDescription: 'Įvykio aprašymas',
    cascoNeeded: 'Ar reikalingas kasko?',
    goToCascoClaim: 'Eiti į kasko žalą',
    contacts: 'Nukentėjusiųjų kontaktai',
    notes: 'Papildomi komentarai',
    attachments: 'Pridėti dokumentai',
    downloadAll: 'Atsisiųsti visus',
    selectNewFileCategory: 'Pasirinkite naują failų kategoriją',

    //MTPL specific
    failureReasonList: {
      wrongContacts: 'Neveikiantys kontaktai ',
      disappearedInjuredParty: 'Dingo nukentėjusieji',
      nonNegotiatingInjuredParty: 'Nesiderantys nukentėjusieji',
    },
    damagedPropertyTypes: {
      vehicle: 'Transporto priemonė',
      truck: 'Vilkikas',
      fence: 'Tvora',
      roadBarrier: 'Kelio užtvaras ',
      gates: 'Vartai',
    },
    createCascoClaim: 'Sukurti CASCO žalą',
    //CASCO specific
    insuranceProvider: 'Draudimo kompanija',
    trailerRegistration: 'Priekabos registracija',
    trailerInsuranceNumber: 'Priekabos žalos numeris',
    payoutReceived: 'Gauta išmoka',
    rescueWork: 'Gelbėjimo darbai?',
    isThereDamageToThirdPartyProperty:
      'Ar padaryta žala trečiųjų šalių turtui?',
    thirdPartyDamage: 'Trečiųjų šalių žala',
    //MTPL not liable
    culpritRegistration: 'Kaltininko valstybinis Nr.',
    culpritCountry: 'Kaltininko šalis',
    outcomeForEmployees: 'Komentaras darbuotojams',
    damagedCargo: 'Apgadintas krovinys?',
    //CMR
    cargoClaim: 'Žala kroviniui',
    claimToThirdParty: 'Trečiųjų šalių atsakomybės žala',
    claimToEntrustedProperty: 'Žala patikėtam turtui',
    freightForwardingClaim: 'Ekspedicijos žala',
    warehouseKeepersLiability:
      'Sandėlio operatoriaus civilinės atsakomybės draudimas',
    factualCarrier: 'Faktinis vežėjas',
    isUrgent: 'Atsakymas pretenzijos reiškėjui per 24-72 valandas',
    allowDirectContactWithClaimant: 'Leidimas tiesiogiai bendrauti su ieškovu',
    claimingParty: 'Pretenziją reiškianti šalis',
    declaredForInsurerDate: 'Žalos registracija draudimo bendrovei',
    unloadingDate: 'Iškrovimo data',
    finalInvoiceReceived: 'Gauta įmonei adresuota sąskaita',
    payoutDate: 'Draudimo išmokos data',
    amountReduced: 'Sumažinto nuostolio dydžio suma',
    reserve: 'Rezervas',
    reserveReceived: 'Gautas rezervas',
    lossDocExpNotProvided: 'Nepateiktas lūkestis/nuostolio dokumentas',
    amountRejected: 'Atmestos pretenzijos suma',
    loadingLocation: 'Pakrovimo adresas',
    unloadLocation: 'Iškrovimo adresas',
    cargoType: 'Krovinio tipas:',
    vehicle: 'Transporto priemonė',
    standardCargo: 'Standartinis krovinys',
    addVehicle: 'Pridėti transporto priemonę',
    selectDamagedVehicleParts:
      'Pasirinkti apgadintos transporto priemonės dalis',
    make: 'Markė',
    model: 'Modelis',
    vehicleCondition: 'Transporto priemonės būklė:',
    new: 'Nauja',
    used: 'Naudota',
    removeVehicle: 'Pašalinti transporto priemonę',
    selectCargoType: 'Pasirinkti krovinio tipą',
    missingDamagedCargoWeight: 'Trūkstamas / sugadintas krovinio svoris (kg)',
    missingDamagedCargoAmount:
      'Trūkstamas / sugadintas krovinio kiekis (padėklai / vienetai / dėžės / kita)',
    standardCargoCircumstances:
      'Standartinio krovinio žalos atsiradimo aplinkybės',
    vehicleCircumstances: 'Transporto priemonės žalos atsiradimo aplinkybės',
    claimToEntrustedPropertyCircumstances: 'Žalos patikėtam turtui aplinkybės',
    mandatoryDocuments: 'Privalomi dokumentai',
    repairInService: 'Planuojama remontuoti servise',
    requestEstimate: 'Prašyti draudimo sąmatos',
    cargoVehicles: 'Transporto priemonės',
    // KRD/NA specific
    NAClaim: 'NA Žala',
    KRDClaim: 'KRD Žala',
    hospitalLocation: 'Ligoninės adresas',
    insuredPersonForm: 'Apdrausto asmens forma',
    name: 'Vardas Pavardė',
    birthDate: 'Gimimo data',
    phone: 'Tel. nr.',
    relativeName: 'Apdrausto asmens artimojo vardas pavardė',
    relativePhone: 'Apdrausto asmens artimojo tel. nr.',
    insuredPerson: 'Apdraustas asmuo',
    createNewInsuredPerson: 'Sukurti naują apdraustą asmenį',
    beneficiaryIBAN: 'Draudimo išmokos gavėjo banko sąskaitos nr.',
    expenses: 'Išlaidos',
    hospitalContacts: 'Ligoninės kontaktai (telefonas/el. paštas)',
    //Form buttons
    save: 'Išsaugoti',
    cancel: 'Atšaukti',
    //Vehicle form
    insuredVehicleForm: 'Apdraustos transporto priemonės forma',
    registration: 'Valstybinis numeris',
    modelYear: 'Modelio metai',
    //Image Viewer
    imageViewer: 'Paveikslų peržiūra',
    //Driver form
    driverForm: 'Vairuotojo forma',
    // Validations
    timeFormatValidation: 'Laiko formatas turi būti HH:mm',
    dateFormatValidation: 'Datos formatas turi būti YYYY-MM-DD',
    // CP Claim
    damagedObject: 'Apgadintas objektas',
    // Stage
    selectPreviousStage: 'Pasirinkti ankstesnį statusą',
    selectNextStage: 'Pasirinkti sekantį statusą',
    stage: 'Statusas',
    // Validation labels
    client: 'Įmonė',
    circumstance: 'Aplinkybė',
    subtypeCheckboxes: 'Būtina pažymėti vieną arba abi rizikas',
    //Latest reminder label
    nextUpdateScheduled: 'Kitas atnaujinimas numatytas:',
    //CMR filecategory
    carriersCmrWaybill: 'Vežėjo CMR važtaraštis',
    proofThatCargoWasDeliveredToAWarehouse:
      'Dokumentai, įrodantys prekių atsiradimą sandėlyje',
    markColour: 'Pažymėti',
    unmarkColour: 'Nuimti žymėjimą',
    transferredToRepresentatives: 'Perduota atstovams',
  },
  vehicleParts: {
    wholeVehicle: 'Visa transporto priemonė',
    frontBumper: 'Priekinis bamperis',
    bottomOfTheFrontBumper: 'Priekinio bamperio apačia',
    rearBumper: 'Galinis bamperis',
    bottomOfTheRearBumper: 'Galinio bamperio apačia',
    hood: 'Kapotas',
    headlights: 'Priekiniai žibintai',
    taillights: 'Galiniai žibintai',
    roof: 'Stogas',
    frontWing: 'Priekinis sparnas',
    rearWing: 'Galinis sparnas',
    sill: 'Slenkstis',
    'luggageLid/Tailgate': 'Bagažinės dangtis / galinis dangtis',
    windshield: 'Priekinis stiklas',
    backWindow: 'Galinis stiklas',
    vent: 'Šoninis stiklas',
    frontDoorRightSide: 'Priekinės durys dešinėje pusėje',
    rearDoorRightSide: 'Galinės durys dešinėje pusėje',
    frontDoorLeftSide: 'Priekinės durys kairėje pusėje',
    rearDoorLeftSide: 'Galinės durys kairėje pusėje',
    underbody: 'Kėbulo apačia',
    rim: 'Ratlankis',
    tyre: 'Padanga',
    mirror: 'Veidrodėlis',
    antenna: 'Antena',
  },
  standardCargoItems: {
    foodItems: 'Maisto produktai',
    'non-alcoholicBeverages': 'Nealkoholiniai gėrimai',
    alcoholicBeverages: 'Alkoholiniai gėrimai',
    'metal/steel': 'Metalas/plienas',
    wood: 'Mediena',
    sanitaryProducts: 'Higienos priemonės',
    medicialTechnology: 'Medicininė įranga',
    pharmaceuticalProducts: 'Farmacijos produktai',
    cosmeticItems: 'Kosmetikos prekės',
    electronics: 'Elektronika',
    chemicalProducts: 'Cheminiai produktai',
    constructionMaterials: 'Statybinės medžiagos',
    'fabric/clothingItems': 'Audiniai/drabužiai',
    agriculturalProducts: 'Žemės ūkio produktai',
    paper: 'Popierius',
    furniture: 'Baldai',
    glassItems: 'Stiklo gaminiai',
  },
  circumstances: {
    'Maneuvering in the company / parking area':
      'Manevruojant įmonės/parkingo teritorijoje',
    'While making turn in the road': 'Darant posūkį kelyje',
    'Backwards in the road': 'Važiuojant atbuline eiga kelyje',
    'Hit the end of another vehicle in the road':
      'Atsitrenkimas į kitos transporto priemonės galą kelyje',
    'Changing lanes in the road': 'Rikiuojantis kelyje',
    'Hit standing vehicle (on the side of the road)':
      'Pakelėje/kelkraštyje stovinčios transporto priemonės kliudymas',
    'Unclear liability': 'Neaiški atsakomybė',
    'Oil/fuel spilled': 'Išsilieję tepalai/kuras',
    Other: 'Kita',
    Theft: 'Vagystė',
    Fire: 'Gaisras',
    'Exceeding of the delivery date': 'Pavėluotas pristatymas',
    Fine: 'Bauda',
    'Improper fastening made by driver':
      'Vairuotojas netinkamai pritvirtino krovinį',
    'Improper packaging': 'Netinkama pakuotė',
    'Damage to the third party': 'Trečiajai šaliai padaryta žala',
    'Road accident': 'Eismo įvykis kelyje',
    'Collision with building structures (bridge, viaduct, etc)':
      'Kontaktas su pastato konstrukcija (tiltas, viadukas ir pan.)',
    'Collision with platform at the time of loading':
      'Kontaktas su platforma pakrovimo metu',
    'Collision with platform at the time of delivery':
      'Kontaktas su platforma pristatymo metu',
    'Collision with platform during the transportation':
      'Kontaktas su platforma transportavimo metu',
    'Collision with tree branches': 'Kontaktas su medžių šakomis',
    Rockfall: 'Kontaktas su akmenukais',
    Hail: 'Kruša',
    'Damaged with fastening belt': 'Pažeistas tvirtinimo diržas',
    'Damage was not noticed by driver before take-over':
      'Vairuotojas nepastebėjo žalos prieš perimdamas transporto priemonę',
    'Hidden damage': 'Paslėpti pažeidimai',
    'Damaged caused by third parties': 'Trečiųjų šalių padaryta žala',
    'Vehicle rolled off the trailer':
      'Transporto priemonė nuriedėjo nuo priekabos',
    'Missing accessories': 'Trūkstama komplektacijos dalis',
    'Contaminated product': 'Užterštas produktas',
    'Wrong temperature': 'Netinkamai nustatyta temperatūra',
    'Improper fastening made by sender':
      'Siuntėjas netinkamai pritvirtino krovinį',
    'Improper unloading made by driver':
      'Netinkamai atliktas vairuotojo iškrovimas',
    'Improper unloading made by consignee':
      'Netinkamai atliktas gavėjo iškrovimas',
    'Improper loading made by driver': 'Netinkamas vairuotojo pakrovimas',
    'Improper loading made by sender': 'Netinkamai siuntėjo pakrautas krovinys',
    'Water damage': 'Sušlapęs krovinys',
    'Freight forwarding claim': 'Ekspedicijos žala',
    'Breakdown on the road': 'Kelyje įvykęs gedimas',
    'Crashed into third party property ':
      'Atsitrenkė į trečiosios šalies nuosavybę',
    'Drove off the road': 'Nuvažiavo nuo kelio',
    'Glass damage': 'Stiklo pažeidimai',
    'Traffic accident': 'Kelių eismo įvykis',
    'Burst tire': 'Sprogusi padanga',
    Animal: 'Gyvūnas',
    'During loading/unloading': 'Pakrovimo/iškrovimo metu',
    'Hit the end of vehicle in the road':
      'Atsitrenkimas į transporto priemonės galą kelyje',
    'Hit standing vehicle': 'Atsitrenkimas į stovinčią transporto priemonę',
    'Unclear liabilty': 'Neaiški atsakomybė',
    'At a roundabout': 'Žiedinėje sankryžoje',
    'Mutual liability': 'Abipusė atsakomybė',
    'Illegal person activity': 'Nelegalų sukelta žala',
    'Damage due to sudden braking': 'Žala dėl staigaus stabdymo',
    'Driver cannot explain': 'Vairuotojas negali paaiškinti',
    'Shortage at the time of delivery': 'Trūkumas pristatymo metu',
    'Product contamination': 'Produkto užteršimas',
    'Damage due to cargo issues (inproper packaging or fastening)':
      'Žala dėl krovinio (netinkama pakuotė ar tvirtinimas)',
    'Sudden braking': 'Staigus stabdymas',
    'Maneuvering in the company/parking area':
      'Manevruojant įmonės/parkingo teritorijoje',
    'Hit another vehicle/property':
      'Atsitrenkimas į kitą transporto priemonę/turtą',
    'Lost control of the vehicle': 'Prarastas transporto priemonės valdymas',
    'Damage was noted by driver before take-over':
      'Vairuotojas užfiksavo žalą prieš pakrovimą',
    'Boulevard Périphérique': 'Boulevard Périphérique - Žiedas Paryžiuje',
    'In a roundabout': 'Važiuojant žiede',
    "Damage caused by a driver's faulty action":
      'Žala  padaryta dėl vairuotojo klaidos',
  },
  validations: {
    clientRequired: 'Būtina nurodyti įmonę.',
    ICNRequired: 'Būtina nurodyti draudimo žalos numerį.',
    ICNLength: 'Draudimo numerį turi sudaryti ne mažiau kaip 2 simboliai.',
    driverRequired: 'Būtina nurodyti vairuotoją.',
    vehicleRequired: 'Būtina nurodyti transporto priemonę.',
    emailValid: 'El. p. adresas privalo būti galiojantis',
    emailRequired: 'Būtina nurodyti el. p. adresą',
    unloadDateRequired: 'Būtina nurodyti iškrovimo datą.',
    incidentDateRequired: 'Būtina nurodyti įvykio datą.',
    loadingLocationRequired: 'Būtina nurodyti pakrovimo adresą.',
    unloadLocationRequired: 'Būtina nurodyti iškrovimo adresą.',
    nameRequired: 'Būtina nurodyti vardą ir pavardę.',
    insuredPersonRequired: 'Būtina nurodyti apdraustą asmenį.',
    vehicleRegistrationRequired:
      'Būtina nurodyti transporto priemonės registraciją.',
    VINRequired: 'Būtina nurodyti VIN.',
    VINAlphanumeric: 'VIN gali sudaryti tik skaičiai ir raidės.',
    fieldHasErrors: 'laukelyje yra klaidų.',
    incidentDescriptionRequired: 'Būtina nurodyti įvykio aprašymą.',
    incidentLocationRequired: 'Būtina nurodyti įvykio vietą.',
    passwordLength: 'Slaptažodį turi sudaryti 8 ar daugiau simbolių.',
    passwordMatch: 'Slaptažodžiai turi sutapti.',
    dateRequired: 'Būtina nurodyti gimimo datą.',
    KRDNACheckboxes: 'Būtina pažymėti bent vieną draudimo tipą.',
    makeRequired: 'Būtina nurodyti transporto priemonės markę.',
    modelRequired: 'Būtina nurodyti transporto priemonės modelį.',
    damagedPartsRequired:
      'Būtina nurodyti apgadintas transporto priemonės dalis.',
    carriersCmrWaybillRequired: 'Būtina prisegti vežėjo CMR važtaraštį',
    proofThatCargoWasDeliveredToAWarehouseRequired:
      'Būtina prisegti dokumentus, įrodančius prekių atsiradimą sandėlyje.',
    newFileCategoryIsRequired: 'Būtina nurodyti naują failo kategoriją.',
  },
  fileCategories: {
    carriersCmrWaybill: 'Vežėjo CMR važtaraštis',
    carriersCmrInsurancePolicy: 'Vežėjo CMR draudimo polisas',
    consigneesCmrWaybill: 'Gavėjo CMR važtaraštis',
    incidentDeclarationPolice: 'Įvykio deklaracija/policijos pažyma',
    vehicleTrailerRegistrationDocuments:
      'Transporto priemonės/priekabos registracijos dokumentai',
    transportationOrder: 'Transportavimo užsakymas',
    defectAct: 'Defektų aktas',
    photos: 'Nuotraukos',
    repairEstimateRepairInvoice: 'Remonto sąmata/remonto sąskaita faktūra',
    repairEstimateRepairInvoiceOfTheTrailer:
      'Priekabos remonto sąmata/remonto sąskaita faktūra',
    writtenDriversExplanation: 'Raštiškas vairuotojo paaiškinimas',
    packingList: 'Pakuočių sąrašas (packing list)',
    invoiceOfTheCargo: 'Krovinio sąskaita faktūra',
    surveyReport: 'Tyrimo ataskaita',
    claimFinalInvoice: 'Pretenzija/Galutinė sąskaita faktūra',
    correspondenceWithClaimant: 'Komunikacija su pretenzijos reiškėju',
    otherDocuments: 'Kiti dokumentai',
    WrittenDriversExplanation: 'Raštiškas vairuotojo paaiškinimas',
    repairEstimate: 'Remonto sąmata',
    repairInvoice: 'Remonto sąskaita',
    vehiclesConditionReportBeforeLoading:
      'Transporto priemonės būklės ataskaita prieš pakrovimą',
    vehiclesCommercialInvoice: 'Transporto priemonės įsigijimo sąskaita',
    commercialInvoice: 'Komercinė sąskaita faktūra',
    registrationDocuments: 'Registracijos dokumentai',
    driversLicense: 'Vairuotojo pažymėjimas',
    damagedVehiclePropertyPhotos:
      'Apgadintos transporto priemonės/turto nuotraukos',
    requestForTransferringMoney: 'Prašymas pervesti pinigus',
    repairEstimateOrInvoice: 'Remonto sąmata arba sąskaita faktūra',
    policeDocumentsOrAccidentStatement:
      'Policijos dokumentai arba eismo įvykio deklaracija',
    injuredPersonsPassportID: 'Nukentėjusiojo asmens pasas/tapatybės kortelė',
    residencePermit: 'Leidimas gyventi',
    businessTravelOrder: 'Komandiruotės įsakymas',
    europeanHealthInsuranceCard: 'Europos sveikatos draudimo kortelė (ESDK)',
    medicalDocuments: 'Medicininiai dokumentai',
    invoiceForMedicalServices: 'Sąskaita faktūra už medicinos paslaugas',
    deathCertificate: 'Mirties liudijimas',
    centreOfRegistersDocument: 'Registrų centro dokumentas',
    photosOfTheDamagedItemObject: 'Apgadinto daikto/objekto nuotraukos',
    proofThatCargoWasDeliveredToAWarehouse:
      'Dokumentai, įrodantys prekių atsiradimą sandėlyje',
    rescueInvoice: 'Gelbėjimo sąskaita',
  },
  //Titles
  title: {
    user: 'Vartotojas',
    client: 'Klientas',
    activityLog: 'Veiksmų žurnalas',
    driver: 'Vairuotojas',
    vehicle: 'Transporto priemonė',
    insuredPerson: 'Apdraustas asmuo',
    claim: 'Žala',
    messages: 'Žinutės',
    login: 'Prisijungti',
    hive: 'Avilys',
    clientView: 'Kliento peržiūra',
    file: 'Failas',
    selectInsuranceType: 'Pasirinkite draudimo rūšį',
  },
  tabs: {
    data: 'Duomenys',
    insight: 'Įžvalgos',
    import: 'Importas',
    vehicle: 'Transporto priemonė',
    vehicleImport: 'Transporto priemonės importas',
    driver: 'Vairuotojas',
    driverImport: 'Vairuotojo importas',
    insuredPerson: 'Apdraustas asmuo',
  },
  buttons: {
    claimRegistration: 'Žalos registravimas',
    cancel: 'Atšaukti',
    ok: 'Gerai',
    save: 'Išsaugoti',
    delete: 'Ištrinti',
    login: 'Prisijungti',
    today: 'Šiandien',
    day: 'Diena',
    week: 'Savaitė',
    month: 'Mėnuo',
    days: '4 dienos',
    exportClaims: 'Eksportuoti',
    exportClientClaims: 'Eksportuoti žalas klientams',
    reset: 'Nustatyti iš naujo',
    changeFileCategory: 'Keisti failo kategoriją',
  },
  forms: {
    editUser: 'Redaguoti vartotoją',
    editDriver: 'Redaguoti vairuotoją',
    clientForm: 'Kliento forma',
    companyName: 'Įmonės pavadinimas',
    name: 'Vardas',
    email: 'El. paštas',
    phone: 'Telefono nr.',
    address: 'Adresas',
    registrationNumber: 'Įmonės kodas',
    taxNumber: 'Mokesčių mokėtojo kodas',
    bankInfo: 'Sąskaitos banko informacija',
    account: 'Sąskaitos nr.',
    bank: 'Bankas',
    swift: 'SWIFT',
    newVehicle: 'Nauja transporto priemonė',
    editVehicle: 'Redaguoti transporto priemonę',
    newInsuredPerson: 'Naujas apdraustas asmuo',
    editInsuredPerson: 'Redaguoti apdraustą asmenį',
    provideEmailAddressForPasswordReset:
      'Nurodykite el. pašto adresą slaptažodžio atkūrimui',
    changeFileCategoryForm: 'Failo kategorijos keitimo forma',
  },
  headers: {
    name: 'Vardas',
    email: 'El. paštas',
    role: 'Rolė',
    claim: 'Žalos nr.',
    client: 'Įmonė',
    vehicleNumber: 'Transporto priemonė',
    trailerRegistration: 'Priekabos registracija',
    icn: 'Draudimo žalos nr.',
    incidentDate: 'Įvykio data',
    birthDate: 'Gimimo data',
    relativeName: 'Artimojo vardas',
    relativePhone: 'Artimojo tel. nr',
    driver: 'Vairuotojas',
    circumstance: 'Aplinkybė',
    stage: 'Statusas',
    insuredPerson: 'Apdraustas asmuo',
    location: 'Lokacija',
    phone: 'Tel.nr',
    outcome: 'Bylos eiga',
    insuranceProvider: 'Draudimo kompanija',
    duration: 'Trukmė',
    unloadingDate: 'Iškrovimo data',
    reserve: 'Rezervas',
    firDuration: 'FIR trukmė',
    krdClaim: 'KRD Žala',
    naClaim: 'NA Žala',
    paid: 'Apmokėta',
    contacts: 'Kontaktai',
    cmr: 'CMR',
    registration: 'Registracija',
    make: 'Markė',
    model: 'Modelis',
    modelYear: 'Modelio metai',
    year: 'Metai',
    stageChangeDate: 'Statuso pakeitimo data',
    'VIN/CT': 'VIN/Krovinio tipas',
    mark: 'Žyma',
    actions: 'Veiksmai',
  },
  filters: {
    client: 'Įmonė',
    stage: 'Statusas',
    from: 'Nuo',
    to: 'Iki',
    followed: 'Sekama',
    open: 'Atidaryta',
    closed: 'Uždaryta',
    suspended: 'Sustabdyta ',
    marked: 'Pažymėta',
    new: 'Nauja',
    currentYear: 'Einamieji metai',
    standardCargo: 'Standartinis krovinys',
    vehicle: 'Transporto priemonė',
    claimToThirdParty: 'Trečiųjų šalių atsakomybės žala',
    claimToEntrustedProperty: 'Žala patikėtam turtui',
    freightForwardingClaim: 'Ekspedicijos žala',
  },
  placeholders: {
    search: 'Ieškoti',
  },
  insuranceProviders: {
    culpritsInsuranceCompany: 'Kaltininko draudimo kompanija',
    contractNotConcludedViaInsuranceBee:
      'Draudimo sutartis sudaryta netarpininkaujant "Draudimo bitės"',
    other: 'Kita',
  },
  dialogMessages: {
    itemDeleteConfirmation: 'Ar tikrai norite ištrinti šį elementą?',
    fileDeleteConfirmation: 'Ar tikrai norite ištrinti šį failą?',
    vehicleDeleteThisVehicle:
      'Ar tikrai norite ištrinti šią transporto priemonę?',
    couldNotUploadTheFile: 'Nepavyko įkelti failo!',
    deleteConfirmation: 'Ar tikrai norite ištrinti?',
    unsavedFile: 'Turite neišsaugotų dokumentų. Ar tikrai norite išeiti?',
    cascoClaimConfirmation:
      'Ar tikrai norite sukurti Casco žalą pagal šią MTPL žalą?',
    stageChangeDialog: 'Pasirinkite etapą į kurį norite pakeisti žalai: ',
  },
  imports: {
    importPreview: 'Importo peržiūra',
    importDrivers: 'Importuoti vairuotojus',
    importVehicles: 'Importuoti transporto priemones',
    imported: 'Importuota',
    failedToImport: 'Importas nepavyko',
    importClaims: 'Importuoti žalas',
    import: 'Importuoti',
    clear: 'Išvalyti',
  },
  followerBar: {
    contacts: 'Kontaktai',
    following: 'Sekama',
    follow: 'Sekti',
    email: 'el.p.',
    phone: 'tel.nr',
  },
  impersonationBar: {
    youAreLoggedInAs: 'Esate prisijungęs kaip',
    andCurrentlyImpersonating: 'ir šiuo metu apsimetate',
  },
  messages: {
    deleteMessage: 'Ištrinti žinutę',
    typeSomething: 'Įrašykite',
  },
  notificationBar: {
    newClaimCreated: 'Sukurta nauja žala.',
    claimNumberHasBeenAssigned: 'Suteiktas žalos numeris',
    claimStageHasChanged: 'Pasikeitė žalos statusas',
    messageInClaim: 'Nauja žinutė',
    fileChangesInClaim: 'Įkeltas dokumentas',
    claim: 'Žala',
    showAll: 'Rodyti viską',
  },
  stageReminder: {
    claimReminder: 'Žalos priminimas',
    reminderDate: 'Priminimo data',
    reminderTime: 'Priminimo laikas',
    message: 'Žinutė',
    setReminder: 'Nustatyti priminimą',
    updateReminder: 'Atnaujinti priminimą',
    close: 'Uždaryti',
  },
  fileUpload: {
    dropFilesHere: 'Tempkite dokumentus čia',
  },
  snackbar: {
    reminderDeletedSuccessfully: 'Priminimas sėkmingai ištrintas',
    reminderWasNotDeleted: 'Priminimas nebuvo ištrintas',
    reminderUpdatedSuccessfully: 'Priminimas sėkmingai atnaujintas',
    reminderWasNotUpdated: 'Priminimas nebuvo atnaujintas',
    failedToLogin: 'Prisijungti nepavyko',
    couldNotRetrieveClaimNumber: 'Žalos numerio generavimas nepavyko',
    registeredSuccessfully: 'sėkmingai užregistruota',
    createdSuccessfully: 'sėkmingai sukurta',
    reminderFor: 'Priminimas dėl',
    reminderWasNotCreated: 'Priminimas nebuvo sukurtas',
    couldNotRegisterUser: 'Vartotojo registravimas nepavyko',
    clientChangesSaved: 'Kliento pakeitimai išsaugoti',
    clientChangesWereNotSaved: 'Kliento pakeitimai nebuvo išsaugoti',
    deletedSuccessfully: 'sėkmingai ištrinta',
    clientWasNotDeleted: 'Klientas nebuvo ištrintas',
    clientCreatedSuccessfully: 'Klientas sukurtas sėkmingai',
    clientWasNotCreated: 'Klientas nebuvo sukurtas',
    couldNotImportDrivers: 'Vairuotojų importas nepavyko',
    passwordSetSuccessfully: 'Slaptažodis sėkmingai nustatytas',
    loginWithNewPassword: 'Prisijungti nauju slaptažodžiu',
    passwordResetRequestSuccessful: 'Slaptažodžio keitimo užklausa sėkminga',
    passwordResetRequestUnsuccessful: 'Slaptažodžio keitimo užklausa nepavyko',
    claimDeletedSuccessfully: 'Žala ištrinta sėkmingai',
    claimWasNotDeleted: 'Nepavyko ištrinti žalos',
    claimUpdatedSuccessfully: 'Žala atnaujinta sėkmingai',
    claimUpdateFailed: 'Nepavyko atnaujinti žalos',
    couldNotImportVehicles: 'Nepavyko importuoti transporto priemonių',
    couldNotCreateVehicle:
      'Nepavyko sukurti transporto priemonės. Patikrinkiite ar tokia dar neegzistuoja.',
    notificationsCouldNotBeDeleted: 'Nepavyko ištrinti perspėjimų',
    notificationsDeletedSuccessfully: 'Perspėjimai ištrinti sėkmingai',
  },

  stages: {
    Open: 'Atidaryta',
    'Paid/Closed': 'Apmokėta/Uždaryta',
    'Missing contact details': 'Trūksta kontaktinių duomenų',
    'Large claim': 'Didelė žala, tik registruoti draudime',
    'Reported by insurer': 'Sužinojome iš draudimo',
    'Negotiating with claimant': 'Derybos su pretenzijos reiškėju',
    'Negotiation failed': 'Susitarti tiesiogiai nepavyko',
    'Awaiting calculations': 'Laukiama paskaičiavimų',
    'Calculations received': 'Gauti paskaičiavimai',
    'Negotiating claim sum': 'Derybos dėl žalos dydžio',
    'Agreed, but unpaid': 'Susitarta, bet nemokėta',
    'Preparing payment documents': 'Ruošiami dokumentai mokėjimui',
    'Awaiting payment': 'Laukiama kliento pavedimo',
    'Awaiting waiver': 'Laukiama pretenzijų atsisakymo rašto',
    'Registered with insurer': 'Tik registruoti draudime',
    'Closed/Payment from insurer received': 'Uždaryta gavus draudimo išmoką',
    'Payout received': 'Gauta išmoka',
    'Insurance verification pending': 'Tikrinamas draustumas',
    'Waiting for liability confirmation': 'Laukiamas atsakomybės patvirtinimas',
    'Liability declined': 'Atsakomybė atmesta',
    'Liability confirmed': 'Atsakomybė patvirtinta',
    'Waiting for payment confirmation': 'Laukiamas mokėjimo patvirtinimas',
    'Closed without payout': 'Uždaryta be išmokos',
    'Shared liability': 'Dalinama atsakomybė',
    'No registration for insurer': 'Neregistruojama draudikui',
    'Missing documents': 'Trūksta dokumentų',
    'Awaiting estimated costs': 'Laukiama nuostolio dydžio detalizacija',
    'Sent for surveyor': 'Išsiųsta ekspertui peržiūrėti',
    "Sent for insurer's review": 'Išsiųsta draudikui peržiūrėti',
    "Waiting for insurer's approval": 'Laukiama draudiko patvirtinimo',
    'Reduced/Closed with payment': 'Sumažinta/Uždaryta gavus draudimo išmoką',
    "Closed/Don't exceed deductible": 'Uždaryta / Nesiekia išskaitos',
    "Reduced/Don't exceed deductible": 'Sumažinta / Nesiekia išskaitos',
    'Successfully rejected': 'Sėkmingai atmesta',
    'Closed without payment': 'Uždaryta be draudimo išmokos',
    'Large claim/survey opportunity':
      'Didelės pretenzijos/ekspertizės galimybė',
    'Missing documents (LC)': 'Trūksta dokumentų',
    'Awaiting estimated costs (LC)': 'Laukiama nuostolio dydžio detalizacija',
    'Closed without payment/time bar':
      'Uždaryta be mokėjimo/suėjęs senaties terminas',
    'Cost confirmed - waiting for invoice':
      'Nuostolio dydis patvirtintas - laukiama galutinės pretenzijos',
    'Awaiting response from the loading company':
      'Laukiama atsakymo iš krovimo įmonės',
    'Awaiting police report': 'Laukiama policijos pažyma',
    'Liability in dispute': 'Atsakomybės ginčas',
    'Paid (remaining claim for cargo)':
      'Apmokėta (liko pretenzija dėl krovinio)',
    'Missing documents from client': 'Trūksta dokumentų iš kliento',
    'Sent for insurer’s review': 'Išsiųsta draudikui peržiūrėti',
    "Claim is being handled by culprit's insurance":
      'Žala administruojama per kaltininko draudiką',
    'Closed/Payment from culprit insurer received':
      'Uždaryta/Gautas kaltininko draudiko mokėjimas',
    Closed: 'Uždaryta',
    'Missing documents from other parties': 'Trūksta dokumentų iš kitų šalių',
    'Rejection sent to claimant': 'Atmetinėjama',
    'Waiting for damage estimate/invoice': 'Laukiami nuostolio dokumentai',
    'Closed without claims': 'Uždaryta be pretenzijų',
    'Awaiting client‘s clarification': 'Laukiamas patikslinimas iš kliento',
    'Awaiting information from insurer':
      'Laukiama informacija iš kliento draudiko',
    'Awaiting information from claimant‘s insurer':
      'Laukiama informacija iš nukentėjusiųjų draudiko',
    'Resolving issue of liability': 'Sprendžiamas atsakomybės klausimas',
    'Forwarded to MTPL - not liable': 'Perduota TPVCA, kai esate nekalti',
    'Court procedure': 'Teismo klausimas',
    'Awaiting payment of Claim solution invoice':
      'Laukiamas apmokėjimas Žalų sprendimams',
    'Claim administration suspended': 'Sustabdytas administravimas',
    'Cost confirmed, awaiting insurer’s payment':
      'Nuostolio dydis patvirtintas, laukiama draudiko apmokėjimo',
    'Reduced/closed without payment': 'Sumažinta/uždaryta be draudimo išmokos',
  },

  sideBarLinks: {
    MTPL: 'TPVCA, kai esate kalti',
    CMR: 'Pervežimo paslaugų atsakomybės draudimas (CMR)',
    CASCO: 'KASKO',
    'MTPL Injured': 'TPVCA, kai esate nukentėję',
    'KRD/NA': 'KRD/NA',
    BCA: 'BCA',
    CP: 'Įmonių turtas',
    CI: 'Krovinių draudimas',
  },

  sideBarLinksTooltips: {
    MTPL: 'Civilinės atsakomybės įvykis, kai esate kalti',
    CMR: 'Pervežimo paslaugų atsakomybės draudimas (CMR)',
    CASCO: 'Kasko',
    'MTPL Injured': 'Civilinės atsakomybės įvykis, kai esate nukentėję',
    'KRD/NA': 'Kelionių rizikos/Nelaimingų atsitikimų draudimas',
    BCA: 'Bendrosios civilinės atsakomybės draudimas',
    CP: 'Įmonių turtas',
    CI: 'Krovinių draudimas',
  },

  dataTable: {
    sortBy: 'Rikiuoti pagal',
  },
}
