import NotificationService from '../../services/NotificationService'
import i18n from '@/plugins/i18n'

export const namespaced = true
export const state = {
  notifications: [],
}
export const getters = {
  notifications: (state) => {
    return state.notifications.map((n) => {
      let { claimId, message, pipelineSlug, claimNumber, stageName } =
        JSON.parse(n.args)
      return {
        ...n,
        claimId,
        message,
        pipelineSlug,
        claimNumber,
        stageName,
      }
    })
  },
  unseen: (state) => {
    return state.notifications.filter(
      (notification) => notification.seenAt == null
    ).length
  },
}
export const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  CLEAR_NOTIFICATIONS(state) {
    state.notifications = []
  },
  MARK_READ(state, notificationId) {
    state.notifications = state.notifications.map((n) => {
      if (n.id == notificationId) {
        return { ...n, seenAt: Date.now() }
      }
      return n
    })
  },
  MARK_UNREAD(state, notificationId) {
    state.notifications = state.notifications.map((n) => {
      if (n.id == notificationId) {
        return { ...n, seenAt: null }
      }
      return n
    })
  },
  REMOVE_NOTIFICATION(state, notificationId) {
    state.notifications = state.notifications.filter(
      (n) => n.id != notificationId
    )
  },
  DO_NOTHING() {},
}
export const actions = {
  async getNotifications({ commit }) {
    NotificationService.getNotifications().then((response) => {
      commit('SET_NOTIFICATIONS', response.data.notifications)
    })
  },
  async markRead({ commit }, notificationId) {
    NotificationService.markRead({ ids: [notificationId] }).then(() => {
      commit('MARK_READ', notificationId)
    })
  },
  async markUnread({ commit }, notificationId) {
    NotificationService.markUnread(notificationId).then(() => {
      commit('MARK_UNREAD', notificationId)
    })
  },
  async deleteNotification({ commit }, notificationId) {
    NotificationService.deleteNotification(notificationId).then(() => {
      commit('REMOVE_NOTIFICATION', notificationId)
    })
  },
  async deleteSelectedNotifications({ dispatch }, notificationIds) {
    let notificationIdsSet = [...new Set(notificationIds)]
    let promises = notificationIdsSet.map((notificationId) =>
      dispatch('deleteNotification', notificationId)
    )
    try {
      await Promise.all(promises)
      let snackbar = {
        // text: `Success`,
        text: `${i18n.t('snackbar.notificationsDeletedSuccessfully')}`,
        color: 'success',
      }
      dispatch('snackbar/addSnackbar', snackbar, {
        root: true,
      })
    } catch (error) {
      let snackbar = {
        text: `${i18n.t('snackbar.notificationsCouldNotBeDeleted')}`,
        color: 'error',
      }
      dispatch('snackbar/addSnackbar', snackbar, {
        root: true,
      })
    }
  },
  clearNotifications({ commit }) {
    commit('CLEAR_NOTIFICATIONS')
  },
}
