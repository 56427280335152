var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"offset":"3","cols":"4","sm":"4"}},[(_vm.clients)?_c('v-autocomplete',{attrs:{"items":_vm.selectClients,"value":"formData.cliendId","label":_vm.$t('wizard.selectCompany'),"clearable":""},on:{"change":_vm.selectClient},model:{value:(_vm.formData.clientId),callback:function ($$v) {_vm.$set(_vm.formData, "clientId", $$v)},expression:"formData.clientId"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"offset":"3","cols":"3","sm":"3"}},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"500px","height":"300px"},attrs:{"center":_vm.center,"zoom":7,"options":{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUI: false,
        },"map-type-id":"terrain"}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true},on:{"click":function($event){_vm.center = m.position}}})}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }