<template>
  <v-snackbar
    :color="snackbar.color ? snackbar.color : ''"
    v-model="showSnackbar"
  >
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
        X
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    snackbar: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showSnackbar: true,
      timeout: null,
    }
  },
  mounted() {
    this.timeout = setTimeout(
      () => this.$store.dispatch('snackbar/remove', this.snackbar),
      5000
    )
  },
  onBeforeUnmount() {
    clearTimeout(this.timeout)
  },
}
</script>

<style lang="scss" scoped></style>
