import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from 'vuetify/es5/locale/en'
import lt from 'vuetify/es5/locale/lt'

Vue.use(Vuetify)

const opts = {
  lang: {
    locales: { en, lt },
    current: localStorage.getItem('lang') || 'en',
  },
  theme: {
    themes: {
      light: {
        primary: '#EC7B29',
        accent: '#F2AB22',
        info: '#EC7B29',
      },
    },
  },
}

export default new Vuetify(opts)
