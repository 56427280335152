<template>
  <v-card elevation="6" max-width="350px" :loading="loading">
    <template slot="progress">
      <v-overlay absolute class="d-flex flex-column text-center">
        <div>
          <v-progress-circular size="75" color="accent" indeterminate>
            <span>Loading</span>
          </v-progress-circular>
        </div>
      </v-overlay>
    </template>
    <v-card-text :style="colorStyleObject" class="display-1 pa-0 ma-0">
      <v-row>
        <v-col>
          <v-icon :style="colorStyleObject" class="mb-2">
            {{ icon }}
          </v-icon>
          {{ label }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col>
          <v-card-text class="display-1 font-weight-bold pa-0 ma-0">{{
            value
          }}</v-card-text>
        </v-col>
        <!-- <v-spacer></v-spacer> -->
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
//TODO: Fix size difference, when overlay is on.
//TODO: Fix overlay scroll away.
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'green',
    },
    value: {
      type: [String, Number],
      default: '0',
    },
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorStyleObject() {
      return {
        color: this.color,
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
