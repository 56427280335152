import MessageService from '../../services/MessageService'

export const namespaced = true
export const state = {
  messages: [],
}
export const getters = {
  value: (state) => {
    return state.value
  },
}
export const mutations = {
  updateValue(state, payload) {
    state.value = payload
  },
  SET_MESSAGES(state, messages) {
    state.messages = messages
  },
  CLEAR_MESSAGES(state) {
    state.messages = []
  },
  ADD_MESSAGE(state, message) {
    state.messages = [message, ...state.messages]
  },
  REMOVE_MESSAGE(state, messageId) {
    state.messages = Object.assign(
      [],
      state.messages.filter((m) => m.id != messageId)
    )
  },
}
export const actions = {
  updateValue({ commit }, payload) {
    commit('updateValue', payload)
  },
  async getMessages({ commit }, claimId) {
    return MessageService.getMessages(claimId).then((response) => {
      commit('SET_MESSAGES', response.data.messages)
    })
  },
  async sendMessage({ commit }, { claimId, message }) {
    return MessageService.sendMessage(claimId, message).then((response) => {
      commit('ADD_MESSAGE', response.data.message)
    })
  },
  async deleteMessage({ commit }, messageId) {
    // TODO: turn on MessageService delete
    return MessageService.deleteMessage(messageId).then(
      commit('REMOVE_MESSAGE', messageId)
    )
  },
  clearMessages({ commit }) {
    commit('CLEAR_MESSAGES')
  },
}
